/*eslint-disable*/
import React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import Iconify from './Iconify';

export default function CustomDrawer({ open, onClose, title, children }) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div style={{ width: 500 }}>
        <Stack spacing={1} sx={{ p: 3, pt: 1, justifyContent: 'space-between' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" component="div">
              {title}
            </Typography>

            {/* Close Button */}
            <IconButton onClick={onClose} sx={{ color: 'inherit', backgroundColor: 'inherit' }}>
              <Iconify icon="charm:cross" />
            </IconButton>
          </Box>
          <Divider />
        </Stack>
      </div>
      <div className="drawer-styling">
        {children}
      </div>
    </Drawer>
  );
}
