import { LoadingButton } from "@mui/lab";
import {
    Card, CardContent,
    Button,
    Box,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularLoader, Iconify, Page } from "src/components";
import { _add_update_item, _edit_update_item } from "src/DAL/manageUpdates";
import { _upload_file } from "src/DAL/uploads";
import { s3baseUrl } from "src/config/config";

function AddUpdate() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [buttonLoader, setButtonLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [pageTitle, setPageTitle] = useState("Add New Update")
    const [features, setFeatures] = useState([{ text: "", description: "", image: null }]);
    const [formInputs, setFormInputs] = useState({
        _id: 0,
        title: "",
        description: "",
        version: "",
        status: "false",
        features: features,
    });
    const handleFeatureChange = (index, e) => {
        const { name, value } = e.target;
        setFeatures((prev) =>
            prev.map((feature, i) =>
                i === index ? { ...feature, [name]: value } : feature
            )
        );
    };

    const handleFeatureImageChange = (index, e) => {
        const file = e.target.files[0];
        setFeatures((prev) =>
            prev.map((feature, i) =>
                i === index ? { ...feature, image: file } : feature
            )
        );
    };

    const handleChange = (e) => {
        setFormInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setButtonLoader(true);
            console.log(typeof (features), "kdjdka");
            let formData = new FormData();
            formData.append("title", formInputs.title);
            formData.append("description", formInputs.description);
            formData.append("version", formInputs.version);
            formData.append("is_published", formInputs.status);

            let allFeatures = await Promise.all(features.map(async (item) => {
                if (item.image instanceof File) {
                    const imageFormData = new FormData();
                    imageFormData.append("directory", "images/");
                    imageFormData.append("upload_file", item.image);

                    const imgResult = await _upload_file(imageFormData);
                    if (imgResult.code == 200) {
                        return { ...item, image: imgResult.path };
                    } else {
                        enqueueSnackbar(imgResult.message, { variant: "error" });
                        return item;
                    }
                }
                return item;
            }));

            formData.append("features", JSON.stringify(allFeatures));
            const result = await _add_update_item(formData);

            if (result.code == 200) {
                enqueueSnackbar(result.message, {
                    variant: "success",
                });
                navigate(-1);
            }
            else {
                enqueueSnackbar(result.message, {
                    variant: "error",
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setButtonLoader(false);
        }
    };

    const handleEditUpdate = async (e) => {
        e.preventDefault();
        try {
            setButtonLoader(true);
            console.log(typeof (features), "kdjdka");
            let formData = new FormData();
            formData.append("update_id", formInputs._id);
            formData.append("title", formInputs.title);
            formData.append("description", formInputs.description);
            formData.append("version", formInputs.version);
            formData.append("is_published", formInputs.status);
            let allFeatures = await Promise.all(features.map(async (item) => {
                if (item.image instanceof File) {
                    const imageFormData = new FormData();
                    imageFormData.append("directory", "images/");
                    imageFormData.append("upload_file", item.image);

                    const imgResult = await _upload_file(imageFormData);
                    if (imgResult.code == 200) {
                        return { ...item, image: imgResult.path };
                    } else {
                        enqueueSnackbar(imgResult.message, { variant: "error" });
                        return item;
                    }
                }
                return item;
            }));

            formData.append("features", JSON.stringify(allFeatures));
            for (let pair of formData.entries()) {
                console.log(pair[0], " : ", pair[1]);
            }

            const result = await _edit_update_item(formData);

            if (result.code == 200) {
                enqueueSnackbar(result.message, {
                    variant: "success",
                });
                navigate(-1);
            }
            else {
                enqueueSnackbar(result.message, {
                    variant: "error",
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setButtonLoader(false);
        }
    };

    useEffect(() => {
        if (state) {
            setPageTitle("Edit Update");
            setFeatures(
                state.features.map((feature) => ({
                    text: feature.text || "",
                    description: feature.description || "",
                    image: feature.image || null,
                }))
            );

            setFormInputs({
                _id: state._id || 0,
                title: state.title || "",
                description: state.description || "",
                version: state.version || "",
                status: state.status ? "true" : "false",
                features: state.features.map((feature) => ({
                    text: feature.text || "",
                    description: feature.description || "",
                    image: feature.image || null,
                })),
            });
        }
        setIsLoading(false);
    }, [state]);

    if (isLoading) {
        return (
            <div>
                <CircularLoader />
            </div>
        );
    }

    return (
        <Page title={pageTitle ? pageTitle : "Add New Update"}>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" mb={4.5}>
                    <IconButton onClick={() => navigate(-1)}>
                        <Iconify icon="ic:round-arrow-back" />
                    </IconButton>
                    <Typography variant="h4">{pageTitle ? pageTitle : "Add New Update"} </Typography>
                </Stack>

                <form onSubmit={state ? handleEditUpdate : handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    name="title"
                                    label="Update Title"
                                    value={formInputs.title}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    name="description"
                                    label="Update Description"
                                    value={formInputs.description}
                                    onChange={handleChange}
                                    multiline
                                    rows={6}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    name="version"
                                    type="number"
                                    label="Update Version"
                                    value={formInputs.version}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Update Status</InputLabel>
                                <Select
                                    value={formInputs.status}
                                    name="status"
                                    label="Update Status"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="true">Published</MenuItem>
                                    <MenuItem value="false">Un Published</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className="row m-0 p-0">
                        <div className="col-12 row d-flex justify-content-center align-items-center flex-column">
                            <div className="col-lg-8 col-md-10">
                                <Typography variant="h6" className="mt-4 mb-2" textAlign="center" fontWeight="600" gutterBottom>
                                    Features Section
                                </Typography>
                                {features.map((feature, index) => (
                                    <Grid item xs={8} key={index}>
                                        <Card className="p-2 my-3">
                                            <CardContent>
                                                <Typography variant="h6" gutterBottom>
                                                    Feature {index + 1}
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                required
                                                                name="text"
                                                                label="Feature Title"
                                                                value={feature.text}
                                                                onChange={(e) => handleFeatureChange(index, e)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                required
                                                                name="description"
                                                                label="Feature Description"
                                                                value={feature.description}
                                                                onChange={(e) => handleFeatureChange(index, e)}
                                                                multiline
                                                                rows={6}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box display="flex" flexDirection="column" alignItems="center"
                                                            sx={{ border: '1px solid gray', padding: '4px 0', height: '70px' }}>
                                                            {feature.image ? (
                                                                <img
                                                                    src={feature.image instanceof File
                                                                        ? URL.createObjectURL(feature.image)
                                                                        : s3baseUrl + feature.image}
                                                                    alt="Icon Preview"
                                                                    style={{ height: '100%', width: 'auto', marginBottom: '8px' }}
                                                                />
                                                            ) : (
                                                                <Typography variant="body2" className="mt-3">No Image Selected</Typography>
                                                            )}
                                                        </Box>
                                                        {feature.image ? (
                                                            <Button
                                                                variant="outlined"
                                                                color="inherit"
                                                                className="mt-3"
                                                                onClick={() => {
                                                                    setFeatures((prev) =>
                                                                        prev.map((f, i) => (i === index ? { ...f, image: null } : f))
                                                                    );
                                                                }}
                                                                fullWidth
                                                            >
                                                                Remove Image
                                                            </Button>
                                                        ) : (
                                                            <Button variant="outlined" className="mt-3" color="inherit" component="label" fullWidth>
                                                                Add Image *
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="image"
                                                                    onChange={(e) => handleFeatureImageChange(index, e)}
                                                                    hidden
                                                                />
                                                            </Button>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} className="d-flex justify-content-end align-items-center">
                                                        {features.length > 1 && (
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                className="me-3"
                                                                onClick={() => {
                                                                    setFeatures((prev) => prev.filter((_, i) => i !== index));
                                                                }}
                                                            >
                                                                Delete This Feature
                                                            </Button>
                                                        )}
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setFeatures((prev) => [...prev, { text: "", description: "", image: null }]);
                                                            }}
                                                        >
                                                            Add New Feature
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                                <LoadingButton
                                    loading={buttonLoader}
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    className="mt-1 mb-4"
                                >
                                    {state ? "Edit Update" : "Add Update"}
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </form>
            </Container>
        </Page>
    );
}

export default AddUpdate;
