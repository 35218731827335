/* eslint-disable */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Divider, DialogContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import Iconify from './Iconify';

export default function CustomModal({ open, onClose, title, children }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box sx={{ p: 1, px: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <IconButton onClick={onClose} sx={{ color: 'inherit', backgroundColor: 'inherit', padding: 0 }}>
          <Iconify icon="charm:cross" />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent className='pt-2'>
        {children}
      </DialogContent>
    </Dialog>
  );
}