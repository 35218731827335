import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useLayoutEffect, useState } from "react";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import TableCustomHead from "src/components/TableCustomHead";
// mock
// import transactionList from "../../_mock/user";
//
import { useNavigate } from "react-router-dom";
import TableToolbar from "src/components/TableToolbar";
import MoreMenu from "../../components/MoreMenu";
import { _get_users_list } from "src/DAL/users";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { _get_transactions_list } from "src/DAL/transactions";
import moment from "moment/moment";
import { get_currency_list } from "src/DAL/currency";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: "", label: "#"},
  { id: "user", label: "User", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "plan", label: "Plan", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "date", label: "Subscription Date", alignRight: false },
  { id: "subscription", label: "Subscription", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function applySortFilter(array, query) {
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return array;
}

export default function Transactions() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [transactionList, setTransactionList] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - transactionList.length)
      : 0;

  const filteredUsers = transactionList;
  // const filteredUsers = applySortFilter(transactionList, filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const getTransactionsList = async () => {
    setPageLoading(true);
    try {
      const result = await _get_transactions_list(page, rowsPerPage, {
        search: filterName,
      });
      if (result.code == 200) {
        setTransactionList(result.transaction_list.all_transactions);
        setTransactionCount(result.transaction_list.all_transactions_count);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error, " catched while fetching user list");
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    setTransactionList([]);
    setPageLoading(true);
    getTransactionsList();
  }, [page, filterName]);
  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, filterName]);

  const handleGetCurrencyList = async () => {
    const result = await get_currency_list();
    console.log(result, "result----");
    if (result.code == 200) {
      setCurrencyList([...result?.plan_currency_list]);
    }
  };

  useEffect(() => {
    handleGetCurrencyList();
  }, []);

  return (
    <Page title="Transactions">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Transactions</Typography>
        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Search Transactions..."
          />

          <Scrollbar>
            <TableContainer sx={{overflow: "auto" }}>
              <Table>
                <TableCustomHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {transactionList
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        _id,
                        user_name,
                        email,
                        image,
                        status,
                        name,
                        price,
                        createdAt,
                        plan_currency,
                      } = row;
                      
                      return (
                        <TableRow hover key={_id}>
                          <TableCell align="left">{(page * rowsPerPage) + index +1}</TableCell>
                          <TableCell component="th" scope="row" >
                            <Stack
                            className=""
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={user_name}
                                src={image ? s3baseUrl + image : ""}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {user_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">
                            {name ? name : "Free Plan"}
                          </TableCell>
                          <TableCell align="left">
                            {`${
                              plan_currency == ""
                                ? "$"
                                : currencyList.find(
                                    (item) => item.short_form === plan_currency
                                  )?.symbol || "$"
                            } ${price}`}
                          </TableCell>
                          <TableCell align="left">
                            {moment(createdAt).format("LLL")}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={
                                ((status === "deleted" ||
                                  status === "failed" ||
                                  status === "canceled") &&
                                  "error") ||
                                (status === "requires_action" ||
                                  status === "incomplete" 
                                   && "warning") ||
                                "success"
                              }
                            >
                              {sentenceCase(status ? status : "active")}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {pageLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {isUserNotFound && !pageLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={transactionCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
