import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { _get_editor_keys } from "src/DAL/websiteSettings";
import { useAppContext } from "src/hooks";

// ----------------------------------------------------------------------

const APP_BAR_DESKTOP = "8vh";
const APP_BAR_MOBILE = "8vh";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
  paddingBottom: "0.1rem",
  // backgroundColor: 'grey',
  position: "relative",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingTop: APP_BAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
  // backgroundColor: 'green',
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { keys, setKeys, setKeyLoading } = useAppContext();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const getEditorKey = async () => {
    try {
      const result = await _get_editor_keys();
      if (result.code == 200) {
        setKeys(result.editor_keys);
      }
    } catch (error) {
      console.log(error, " catched while fetching editpr key");
    } finally {
      setKeyLoading(false);
    }
  };
  useEffect(() => {
    if (token) {
      getEditorKey();
    }
  }, []);
  if (!token) {
    return <Navigate to="/login" replace={true} />;
  }else if(pathname == "/"){
    return <Navigate to="/dashboard" replace={true} />;
  }
  return (
    <RootStyle id="mui-root-style">
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle id="mui-main-style">
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
