import { LoadingButton } from "@mui/lab";
import {
    Button,
    Card,
    CardContent,
    Container,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Iconify, MuiDialog, Page } from "src/components";
import CustomDrawer from "src/components/CustomDrawer";
import MuiDrawer from "src/components/MuiDrawer";
import { useAppContext } from "src/hooks";
import { _change_email, _change_password } from "src/DAL/auth";
import { get_from_localStorage, set_to_localStorage } from "src/utils";

const Settings = () => {
    const { _get_user_profile, _set_user_profile } = useAppContext();
    const user = _get_user_profile();
    const [psdOpen, setPsdOpen] = useState(false);
    const [emailOpen, setEmailOpen] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [step, setStep] = useState(1);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
    const [changeEmail, setChangeEmail] = useState(_get_user_profile().user_id.email)
    const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] =
        useState(false);
    const [cancelSubscriptionLoading, setCancelSubscriptionLoading] =
        useState(false);
    const [inputs, setInputs] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [newEmail, setNewEmail] = useState(user.user_id.email);
    const { enqueueSnackbar } = useSnackbar();

    const handleInputChange = (e) => {
        const { target } = e;
        setInputs({ ...inputs, [target.name]: target.value });
    };
    const handleNewInputChange = (e) => {
        setNewEmail(e.target.value);
    };
    const handleOpenEmail = () => {
        setEmailOpen(true);
    };
    const handleCloseEmail = () => {
        setEmailOpen(false);
        setNewEmail("");
        setStep(1);
    };
    const handleOpenPsd = () => {
        setPsdOpen(true);
    };
    const handleClosePsd = () => {
        setPsdOpen(false);
        setInputs({
            newPassword: "",
            confirmPassword: "",
        });
    };
    const handleNav = () => {
        if (step === 1) {
            return;
        }
        setStep((prev) => prev - 1);
    };
    const isNavDisabled = () => {
        if (step === 1) {
            return true;
        }
        if (processing) {
            return true;
        }
        return false;
    };
    const onChangeEmail = async (e) => {
        e.preventDefault();
        console.log(newEmail, "new");
        try {
            let formData = new FormData();
            formData.append("email", newEmail);
            const result = await _change_email(formData);
            if(result.code == 200){
                const profile = get_from_localStorage("user");
                const new_profile = {
                    ...profile,
                    user_id: {
                        ...profile.user_id,
                        email: newEmail 
                    }
                };
                set_to_localStorage("user",new_profile);
                _set_user_profile(new_profile);
                enqueueSnackbar(result.message, {
                    variant: "success" 
                });
                setEmailOpen(false);
            }else{
                enqueueSnackbar(result.message, {
                    variant: "error" 
                });
            }
            
        } catch (error) {
            console.log(error);
        } finally {
            
        }
    };

    const handlePassChange = async (e) => {
        e.preventDefault();
        console.log(inputs, "new");

        try {
            let formData = new FormData();
            formData.append("old_password", inputs.oldPassword);
            formData.append("new_password", inputs.newPassword);
            formData.append("confirm_password", inputs.confirmPassword);
            const result = await _change_password(formData);
            enqueueSnackbar(result.message, {
                variant: result.code == 200 ? "success" : "error",
            });
        } catch (error) {
            console.log(error);
        } finally {
            // setLoadingBtn(false);
            setPsdOpen(false);
        }
    };
    const handleVerifyCode = async () => {
        setEmailOpen(false);
        setStep(1);
        enqueueSnackbar("Email Edited Successfully", { variant: "success" });
    };

    // useEffect(() => {
    //     setChangeEmail(_get_user_profile().user_id.email);
    //     console.log(changeEmail, "dkkdjk");        
    //   }, [_get_user_profile().user_id.email]);

    return (
        <Page title="Settings">
            <Container maxWidth="xl">
                <div className="mb-4">
                    <Typography variant="h4" gutterBottom>
                        Settings
                    </Typography>
                </div>
                <Container maxWidth="sm" sx={{ px: { xs: 0, sm: 1 } }}>
                    <Card>
                        <CardContent>
                            <Stack justifyContent="center" spacing={3}>
                                <Stack
                                    spacing={1}
                                    justifyContent="space-between"
                                    direction={"row"}
                                >
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold" variant="body1">
                                            Email
                                        </Typography>
                                        <Typography variant="body1">{user.user_id.email}</Typography>
                                    </Stack>
                                    <div className="centered-row">
                                        <Button
                                            sx={{ minWidth: "100px" }}
                                            onClick={handleOpenEmail}
                                            size="small"
                                            variant="contained"
                                        >
                                            Change
                                        </Button>
                                    </div>
                                </Stack>
                                <Stack
                                    spacing={1}
                                    justifyContent="space-between"
                                    direction={"row"}
                                >
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold" variant="body1">
                                            Password
                                        </Typography>
                                        <Typography variant="body1">Update Password </Typography>
                                    </Stack>
                                    <div className="centered-row">
                                        <Button
                                            sx={{ minWidth: "100px" }}
                                            onClick={handleOpenPsd}
                                            size="small"
                                            variant="contained"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Container>
                <CustomDrawer
                    open={emailOpen}
                    onClose={handleCloseEmail}
                    title="Change Email"
                >
                    <div className="px-4">
                        {/* {step !== 1 && (
                        <IconButton
                            disabled={isNavDisabled()}
                            sx={{ mb: 1 }}
                            onClick={handleNav}
                        >
                            <Iconify icon="ep:back" />
                        </IconButton>
                    )} */}
                        {step === 1 && (
                            <form onSubmit={onChangeEmail}
                            >
                                <Stack spacing={3}>
                                    <Stack spacing={1.5}>
                                        <Typography>Please enter new email</Typography>
                                        <TextField
                                            variant="outlined"
                                            label="Email"
                                            name="email"
                                            type="email"
                                            value={newEmail}
                                            onChange={handleNewInputChange}
                                        />
                                    </Stack>
                                    <div className="d-flex justify-content-center">
                                        <LoadingButton
                                            loading={processing}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Change
                                        </LoadingButton>
                                    </div>
                                </Stack>
                            </form>
                        )}
                        {/* {step === 2 && (
                        <div
                        >
                            <Stack spacing={3}>
                                <Stack spacing={1.5}>
                                    <Typography>
                                        Please enter the code you received on your email
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label="Verification Code"
                                        name="verificationCode"
                                        type="text"
                                        value={newEmail}
                                        onChange={handleNewInputChange}
                                    />
                                </Stack>
                                <div className="d-flex justify-content-center">
                                    <LoadingButton
                                        loading={processing}
                                        onClick={handleVerifyCode}
                                        variant="contained"
                                    >
                                        Verify
                                    </LoadingButton>
                                </div>
                            </Stack>
                        </div>
                    )} */}
                    </div>
                </CustomDrawer>
                <CustomDrawer
                    open={psdOpen}
                    onClose={handleClosePsd}
                    title="Change Password"
                >
                    <form onSubmit={handlePassChange} className="px-4">
                        <Stack spacing={3}>
                            {/* <TextField
                                tabIndex="2"
                                value={inputs.oldPassword}
                                onChange={handleInputChange}
                                required
                                name="oldPassword"
                                fullWidth
                                autoComplete="current-password"
                                type={showPassword1 ? "text" : "password"}
                                label="Old Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabIndex={4}
                                                edge="end"
                                                onClick={() => setShowPassword1((prev) => !prev)}
                                            >
                                                <Iconify
                                                    icon={
                                                        showPassword1 ? "eva:eye-fill" : "eva:eye-off-fill"
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            /> */}
                            <TextField
                                tabIndex="2"
                                value={inputs.newPassword}
                                onChange={handleInputChange}
                                required
                                name="newPassword"
                                className="mt-2"
                                fullWidth
                                autoComplete="current-password"
                                type={showPassword2 ? "text" : "password"}
                                label="New Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabIndex={4}
                                                edge="end"
                                                onClick={() => setShowPassword2((prev) => !prev)}
                                            >
                                                <Iconify
                                                    icon={
                                                        showPassword2 ? "eva:eye-fill" : "eva:eye-off-fill"
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            
                            <TextField
                                tabIndex="3"
                                value={inputs.confirmPassword}
                                onChange={handleInputChange}
                                required
                                name="confirmPassword"
                                fullWidth
                                autoComplete="current-password"
                                type={showPassword3 ? "text" : "password"}
                                label="Confirm Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabIndex={4}
                                                edge="end"
                                                onClick={() => setShowPassword3((prev) => !prev)}
                                            >
                                                <Iconify
                                                    icon={
                                                        showPassword3 ? "eva:eye-fill" : "eva:eye-off-fill"
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    type="submit"
                                >
                                    Update
                                </LoadingButton>
                            </div>
                        </Stack>
                    </form>
                </CustomDrawer>
            </Container>
        </Page>
    );
};

export default Settings;
