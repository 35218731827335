import { invokeApi } from "src/utils";

export const get_currency_list = async () => {
  const requestObj = {
    path: `api/plan_currency/plan_currency_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_currency = async (data) => {
  const requestObj = {
    path: `api/plan_currency/add_plan_currency`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_currency = async (id, data) => {
  const requestObj = {
    path: `api/plan_currency/update_plan_currency/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_currency = async (id) => {
  const requestObj = {
    path: `api/plan_currency/delete_plan_currency/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
