import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { addCatchup, getLandingPage } from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import { CircularLoader, Page, RichEditor } from "src/components";
import { s3baseUrl } from "src/config/config";

function CatchUp() {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [catchupData, setCatchupData] = useState();
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setCatchupData({
          ...result.data.catch_up,
          file: null,
          blob: "",
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = catchupData;
      if (catchupData.file) {
        const formData = new FormData();
        formData.append("directory", "images/");
        formData.append("upload_file", catchupData.file);
        const imgResult = await _upload_file(formData);
        if (imgResult.code == 200) {
          data["image"] = imgResult.path;
        }
      }
      delete data.file;
      delete data.blob;
      const result = await addCatchup(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setCatchupData(result.data.catch_up);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader x />;
  }
  return (
    <Page title="Catch-up">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Catch-up</Typography>
        </Stack>
        <form action="" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichEditor
                value={catchupData.title}
                setValue={(val) =>
                  setCatchupData((prev) => {
                    return {
                      ...prev,
                      title: val,
                    };
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="watch now button text"
                  value={catchupData.watch_now_button.text}
                  onChange={(e) =>
                    setCatchupData((prev) => {
                      return {
                        ...prev,
                        watch_now_button: {
                          ...prev.watch_now_button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="watch now button link"
                  value={catchupData.watch_now_button.link}
                  onChange={(e) =>
                    setCatchupData((prev) => {
                      return {
                        ...prev,
                        watch_now_button: {
                          ...prev.watch_now_button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  type="color"
                  label="watch now button bg color"
                  value={catchupData.watch_now_button.background_color}
                  onChange={(e) =>
                    setCatchupData((prev) => {
                      return {
                        ...prev,
                        watch_now_button: {
                          ...prev.watch_now_button,
                          background_color: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {(catchupData.blob || catchupData.image) && (
                <img
                  style={{
                    width: "17rem",
                    height: "9rem",
                    // marginLeft: "27rem",
                    marginTop: "1rem",
                  }}
                  src={
                    catchupData.blob
                      ? catchupData.blob
                      : s3baseUrl + catchupData.image
                  }
                  alt="svg"
                />
              )}

              <label htmlFor="icon-button-file">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setCatchupData((prev) => {
                      return {
                        ...prev,
                        file: e.target.files[0],
                        blob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {catchupData.image !== "" ? "Change Image" : "Upload Image"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end mt-3">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Update
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}

export default CatchUp;
