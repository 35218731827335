import React, { useState, useTransition } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { country_list } from "src/constants/constants";
import PropTypes from "prop-types";
CountryPicker.propTypes = {
    sx: PropTypes.object,
    setFormInputs: PropTypes.func.isRequired,
    country: PropTypes.object.isRequired,
};
//---------------------------------------
export default function CountryPicker({
    setFormInputs,
    country = country_list[0],
    sx,
}) {
    const [isPending, startTransition] = useTransition();
    const [value, setValue] = useState(country);
    const [inputValue, setInputValue] = useState("");
    const onInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    const isInvalid = () => {
        let is_invalid = false;
        if (Object.keys(country).length === 0) {
            is_invalid = true;
        }
        return is_invalid;
    };
    const onChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
        if (!newValue) {
            setFormInputs((prev) => {
                return { ...prev, ["country"]: {} };
            });
        } else {
            setFormInputs((prev) => {
                return {
                    ...prev,
                    ["country"]: newValue,
                    ["country_code"]: "+" + newValue.phone,
                };
            });
        }
        startTransition(() => {
            setValue(newValue);
        });
    };
    return (
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            onChange(event, newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            onInputChange(event, newInputValue);
          }}
          id="country-select-demo"
          sx={{ ...sx }}
          options={country_list}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} ({option.code}) +{option.phone}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              error={isInvalid()}
            />
          )}
        />
    );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/country_list.js
