import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get_currency_list } from "src/DAL/currency";
import { _add_payment_plan } from "src/DAL/payment_plans";
import { CircularLoader, Iconify, Page } from "src/components";

function AddNewPlan() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [formInputs, setFormInputs] = useState({
    name: "",
    price: "0",
    workspace_allow: "",
    project_allow: "",
    storage_allow: "",
    manager: "0",
    team_member_allow: "",
    email_support: "no",
    client_allow: "",
    plan_name: "free",
    hours: "",
    period: "weekly",
    storage_unit: "mb",
    workspace_limit: "unlimited",
    project_limit: "unlimited",
    team_limit: "unlimited",
    client_limit: "unlimited",
    status: true,
    plan_currency: "GBP",
    short_description: "Short description",
    detailed_description: "Detailed Description",
    is_plan_free: true,
    with_card: 0,
    payment_access: "yes",
    price: 999,
  });
  const handleChange = (e) => {
    setFormInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    try {
      const req = {
        ...formInputs,
        email_support:
          formInputs.email_support !== "no"
            ? formInputs.hours + " hours " + formInputs.period
            : formInputs.email_support,
        workspace_allow:
          formInputs.workspace_limit !== "unlimited"
            ? formInputs.workspace_allow
            : formInputs.workspace_limit,
        project_allow:
          formInputs.project_limit !== "unlimited"
            ? formInputs.project_allow
            : formInputs.project_limit,
        team_member_allow:
          formInputs.team_limit !== "unlimited"
            ? formInputs.team_member_allow
            : formInputs.team_limit,
        client_allow:
          formInputs.client_limit !== "unlimited"
            ? formInputs.client_allow
            : formInputs.client_limit,
        storage_allow: formInputs.storage_allow + formInputs.storage_unit,
        currency_symbol:
          currencyList.find(
            (item) => item.short_form === formInputs.plan_currency
          )?.symbol || "$",
      };
      delete req.hours;
      delete req.period;
      delete req.workspace_limit;
      delete req.project_limit;
      delete req.team_limit;
      delete req.client_limit;
      delete req.storage_unit;
      delete req.price;
      delete req.plan_name;
      delete req.plan_currency;
      console.log(req, "requsested");
      
      const result = await _add_payment_plan(req);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while adding payment plan");
    } finally {
      setButtonLoader(false);
    }
  };

  const handleGetCurrencyList = async () => {
    setIsLoading(true);
    const result = await get_currency_list();
    console.log(result, "result----");
    if (result.code == 200) {
      setCurrencyList([...result?.plan_currency_list]);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetCurrencyList();
  }, []);

  if (isLoading) {
    return (
      <div>
        <CircularLoader />
      </div>
    );
  }
  return (
    <Page title="Add Plan">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="" mb={4.5}>
          <IconButton onClick={() => navigate(-1)}>
            <Iconify icon="ic:round-arrow-back" />
          </IconButton>
          <Typography variant="h4">Add New Plan</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  name="name"
                  label="Plan Name"
                  value={formInputs.name}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-label">Plan Type</InputLabel>
                <Select
                  id="demo-simple-label"
                  label="Plan Type"
                  value={formInputs.plan_name}
                  name="plan_name"
                  onChange={handleChange}
                >
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="advance">Advance</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formInputs.plan_name !== "free" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-label-currency">Currency</InputLabel>
                    <Select
                      id="demo-label"
                      label="Currency"
                      value={formInputs.plan_currency}
                      name="plan_currency"
                      onChange={handleChange}
                    >
                      {currencyList.map(
                        (item) =>
                          item.status && (
                            <MenuItem
                              value={item.short_form}
                            >{`${item.full_form} (${item.short_form})`}</MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      name="price"
                      label="Price"
                      value={formInputs.price}
                      onChange={(e) => {
                        let temp = e.target.value.replace(
                          /^[^\d]*(\d*\.?\d{0,2}).*$/,
                          "$1"
                        );
                        temp = temp.replace(/(\..*)\./g, "$1");
                        setFormInputs((prev) => {
                          return { ...prev, price: temp };
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-label">Workspaces Allowed</InputLabel>
                <Select
                  id="demo-label"
                  label="Workspaces Allowed"
                  value={formInputs.workspace_limit}
                  name="workspace_limit"
                  onChange={handleChange}
                >
                  <MenuItem value="unlimited">Unlimited</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formInputs.workspace_limit !== "unlimited" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="workspace_allow"
                    label="Workspace Limit"
                    value={formInputs.workspace_allow}
                    onChange={(e) => {
                      let temp = e.target.value.replace(/[^\d]/g, "");
                      temp = temp.replace(/^0+(?=[1-9])/, "");
                      setFormInputs((prev) => {
                        return { ...prev, workspace_allow: temp };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="label">Projects Allowed</InputLabel>
                <Select
                  id="label"
                  label="Projects Allowed"
                  value={formInputs.project_limit}
                  name="project_limit"
                  onChange={handleChange}
                >
                  <MenuItem value="unlimited">Unlimited</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formInputs.project_limit !== "unlimited" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="project_allow"
                    label="Projects Limit"
                    value={formInputs.project_allow}
                    onChange={(e) => {
                      let temp = e.target.value.replace(/[^\d]/g, "");
                      temp = temp.replace(/^0+(?=[1-9])/, "");
                      setFormInputs((prev) => {
                        return { ...prev, project_allow: temp };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <TextField
                  required
                  name="storage_allow"
                  label="Storage Allowed"
                  value={formInputs.storage_allow}
                  onChange={(e) => {
                    let temp = e.target.value.replace(/[^\d]/g, "");
                    temp = temp.replace(/^0+(?=[1-9])/, "");
                    setFormInputs((prev) => {
                      return { ...prev, storage_allow: temp };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="unit">Storage Unit</InputLabel>
                <Select
                  id="unit"
                  value={formInputs.storage_unit}
                  name="storage_unit"
                  onChange={handleChange}
                  label="Storage Unit"
                >
                  <MenuItem value="mb">mb</MenuItem>
                  <MenuItem value="gb">gb</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="asalabel">Team / Managers Allowed</InputLabel>
                <Select
                  id="asalabel"
                  label="Team / Managers Allowed"
                  value={formInputs.team_limit}
                  name="team_limit"
                  onChange={handleChange}
                >
                  <MenuItem value="unlimited">Unlimited</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formInputs.team_limit !== "unlimited" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="team_member_allow"
                    label="Team / Managers Limit"
                    value={formInputs.team_member_allow}
                    onChange={(e) => {
                      let temp = e.target.value.replace(/[^\d]/g, "");
                      temp = temp.replace(/^0+(?=[1-9])/, "");
                      setFormInputs((prev) => {
                        return { ...prev, team_member_allow: temp };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="asalabels">Clients Allowed</InputLabel>
                <Select
                  id="asalabels"
                  label="Clients Allowed"
                  value={formInputs.client_limit}
                  name="client_limit"
                  onChange={handleChange}
                >
                  <MenuItem value="unlimited">Unlimited</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formInputs.client_limit !== "unlimited" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="client_allow"
                    label="Clients Allowed"
                    value={formInputs.client_allow}
                    onChange={(e) => {
                      let temp = e.target.value.replace(/[^\d]/g, "");
                      temp = temp.replace(/^0+(?=[1-9])/, "");
                      setFormInputs((prev) => {
                        return { ...prev, client_allow: temp };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Email Support
                </InputLabel>
                <Select
                  id="demo-simple-select-label"
                  label="Email Support"
                  value={formInputs.email_support}
                  name="email_support"
                  onChange={handleChange}
                >
                  <MenuItem value="no">No</MenuItem>
                  <MenuItem value="yes">Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formInputs.email_support == "yes" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      name="hours"
                      label="Support Time (hours)"
                      value={formInputs.hours}
                      onChange={(e) => {
                        let temp = e.target.value.replace(/[^\d]/g, "");
                        temp = temp.replace(/^0+(?=[1-9])/, "");
                        setFormInputs((prev) => {
                          return { ...prev, hours: temp };
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-select-label">Time Period</InputLabel>
                    <Select
                      id="demo-select-label"
                      label="Time Period"
                      value={formInputs.period}
                      name="period"
                      onChange={handleChange}
                    >
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-label-status">Status</InputLabel>
                <Select
                  id="demo-simple-label-status"
                  label="Status"
                  value={formInputs.status}
                  name="status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>In-Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <LoadingButton
            loading={buttonLoader}
            sx={{ mt: 2 }}
            variant="contained"
            type="submit"
          >
            Add Plan
          </LoadingButton>
        </form>
      </Container>
    </Page>
  );
}

export default AddNewPlan;
