import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//pages
import {
  AddNewPlan,
  AddUser,
  Banner,
  BrandLogo,
  CatchUp,
  Dashboard,
  DriveEfficiency,
  EditorSettings,
  EditPaymentPlan,
  EditUserPlan,
  Footer,
  ForgotPassword,
  Header,
  LandingPageSettings,
  Login,
  MobileApp,
  Page404,
  PaymentPlans,
  PowerfulWork,
  Profile,
  ReadySolutions,
  Register,
  StartProjects,
  Stories,
  SubscriberData,
  TransformTheWay,
  Users,
  WebsiteSettings,
  WorkManagement,
} from "./pages";
import Settings from "./pages/settings/Settings";
import Transactions from "./pages/transactions/Transactions";
import SupportTickets from "./pages/supportTickets/SupportTickets";
import SupportTicketDetails from "./pages/supportTickets/SupportTicketDetails";
import CurrencyListing from "./pages/currencyConfigurations/CurrencyListing";
import ManageUpdates from "./pages/manageUpdates/ManageUpdates";
import AddUpdate from "./pages/manageUpdates/AddUpdates";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <Users /> },
        { path: "transactions", element: <Transactions /> },
        { path: "plans", element: <PaymentPlans /> },
        { path: "currency-configuration", element: <CurrencyListing /> },
        { path: "manage-updates", element: <ManageUpdates /> },
        { path: "add-update", element: <AddUpdate/> },
        { path: "add-plan", element: <AddNewPlan /> },
        { path: "edit-plan", element: <EditPaymentPlan /> },
        { path: "settings/website-settings", element: <WebsiteSettings /> },
        { path: "settings/editor-settings", element: <EditorSettings /> },
        { path: "users/add-user", element: <AddUser /> },
        { path: "profile", element: <Profile /> },
        { path: "settings", element: <Settings /> },
        { path: "edit-user-plan", element: <EditUserPlan /> },
        { path: "landing-page/banner", element: <Banner /> },
        { path: "landing-page/brand-logo", element: <BrandLogo /> },
        { path: "landing-page/catch-up", element: <CatchUp /> },
        { path: "landing-page/drive-efficiency", element: <DriveEfficiency /> },
        { path: "landing-page/footer", element: <Footer /> },
        { path: "landing-page/header", element: <Header /> },
        { path: "landing-page/mobile-app", element: <MobileApp /> },
        { path: "landing-page/powerfull-work", element: <PowerfulWork /> },
        { path: "landing-page/ready-solutions", element: <ReadySolutions /> },
        { path: "landing-page/settings", element: <LandingPageSettings /> },
        { path: "landing-page/start-projects", element: <StartProjects /> },
        { path: "landing-page/stories", element: <Stories /> },
        { path: "landing-page/subscriber-data", element: <SubscriberData /> },
        { path: "landing-page/transform-way", element: <TransformTheWay /> },
        { path: "landing-page/work-management", element: <WorkManagement /> },
        {
          path: "support",
          element: <SupportTickets />,
        },
        {
          path: "support/:id",
          element: <SupportTicketDetails />,
        },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
