import { invokeApi } from "src/utils";
import { get_from_localStorage } from "src/utils";

export const _get_updates_list = ( page, limit) => {
  const reqObj = {
    method: "GET",
    path: `api/new_updates/new_updates_list`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _add_update_item = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/new_updates/add_update`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _delete_update_item = (data) => {
  const reqObj = {
    method: "DELETE",
    path: `api/new_updates/delete_update`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _edit_update_item = (data) => {
    const reqObj = {
      method: "PUT",
      path: `api/new_updates/edit_update`,
      headers: {
        "x-sh-auth": get_from_localStorage("token"),
      },
      postData: data,
    };
    return invokeApi(reqObj);
  };