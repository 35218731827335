import { invokeApi } from "src/utils";

export const _get_payment_plans = () => {
  const requestObj = {
    path: `api/payment_plan/get_subscription_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _add_payment_plan = (data) => {
  const requestObj = {
    path: `api/payment_plan/add_subscription_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_payment_plan = (data) => {
  const requestObj = {
    path: `api/payment_plan/update_subscription_plan`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_payment_plan = (id) => {
  const requestObj = {
    path: `api/subscription/delete_subscription_plan/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
