import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { addStartProjects, getLandingPage } from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import {
  CircularLoader,
  Iconify,
  MuiDialog,
  Page,
  RichEditor,
} from "src/components";
import { s3baseUrl } from "src/config/config";

function StartProjects() {
  const { enqueueSnackbar } = useSnackbar();
  const [startProjectsData, setStartProjectsData] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [addMenuitemDialogOpen, setAddMenuitemDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [newMenuItem, setNewMenuItem] = useState({
    image: "",
    file: null,
    blob: "",
    title: "",
  });
  const [deleteLogoDialog, setDeleteLogoDialog] = useState(false);
  const openDelete = () => {
    setDeleteLogoDialog(true);
  };
  const closeDelete = () => {
    setDeleteLogoDialog(false);
    setEditIndex();
  };
  const confirmDelete = () => {
    setStartProjectsData((prev) => {
      return {
        ...prev,
        steps: prev.steps.filter((item, i) => i !== editIndex),
      };
    });
    closeDelete();
  };
  const openAddItemDialog = () => {
    setAddMenuitemDialogOpen(true);
  };
  const closeAddItemDialog = () => {
    setAddMenuitemDialogOpen(false);
    setNewMenuItem({
      image: "",
      file: null,
      blob: "",
      title: "",
    });
    setIsEditing(false);
    setEditIndex();
  };
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setStartProjectsData({
          ...result.data.start_projects,
          steps: result.data.start_projects.steps.map((item) => {
            return { ...item, file: null, blob: "" };
          }),
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddData = () => {
    setStartProjectsData((prev) => {
      return {
        ...prev,
        steps: [...prev.steps, newMenuItem],
      };
    });
    closeAddItemDialog();
  };
  const handleEditData = () => {
    setStartProjectsData((prev) => {
      let temp_menu = prev.steps;
      temp_menu[editIndex] = newMenuItem;
      console.log(temp_menu, "fdfufuyu");
      return {
        ...prev,
        steps: [...temp_menu],
      };
    });
    closeAddItemDialog();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = startProjectsData;
      let logos = data.steps;
      for (let i = 0; i < logos.length; i++) {
        if (logos[i].file) {
          const formData = new FormData();
          formData.append("directory", "images/");
          formData.append("upload_file", logos[i].file);
          const imgResult = await _upload_file(formData);
          if (imgResult.code == 200) {
            logos[i]["image"] = imgResult.path;
          }
        }
        delete logos[i].file;
        delete logos[i].blob;
        if (logos[i]._id) {
          delete logos[i]._id;
        }
      }
      data["steps"] = logos;
      const result = await addStartProjects(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setStartProjectsData(result.data.start_projects);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Start Projects">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Start Projects</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichEditor
                placeHolder="Title"
                value={startProjectsData.title}
                setValue={(val) =>
                  setStartProjectsData((prev) => {
                    return { ...prev, title: val };
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="See all button text"
                  value={startProjectsData.see_all_button.text}
                  onChange={(e) =>
                    setStartProjectsData((prev) => {
                      return {
                        ...prev,
                        see_all_button: {
                          ...prev.see_all_button,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="See all button link"
                  value={startProjectsData.see_all_button.link}
                  onChange={(e) =>
                    setStartProjectsData((prev) => {
                      return {
                        ...prev,
                        see_all_button: {
                          ...prev.see_all_button,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} mt={5}>
              <Button onClick={openAddItemDialog}>Add Step</Button>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Step Title</TableCell>
                    <TableCell>Step Image</TableCell>
                    <TableCell>Step Link</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {startProjectsData?.steps?.map((val, i) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell>{val.title}</TableCell>
                          <TableCell>{val.link}</TableCell>
                          <TableCell>
                            <img
                              style={{ width: "4.5rem", height: "2.5rem" }}
                              src={val.blob ? val.blob : s3baseUrl + val.image}
                              alt=""
                            />
                          </TableCell>
                          <TableCell>
                            <Iconify
                              onClick={() => {
                                setIsEditing(true);
                                setEditIndex(i);
                                setNewMenuItem(val);
                                openAddItemDialog();
                              }}
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                            />
                          </TableCell>
                          <TableCell>
                            <Iconify
                              onClick={() => {
                                setEditIndex(i);
                                openDelete();
                              }}
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end pb-4">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Dialog
        open={addMenuitemDialogOpen}
        onClose={closeAddItemDialog}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>
              {isEditing
                ? "Edit Ready Solution Step"
                : "Add Ready Solution Step"}
            </p>
            <p onClick={closeAddItemDialog}>
              <Iconify icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form
                  className="gap-3 mt-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (isEditing) {
                      handleEditData();
                    } else {
                      handleAddData();
                    }
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Step Title"
                          required
                          // style={{ width: "48%" }}
                          onChange={(e) =>
                            setNewMenuItem((prev) => {
                              return {
                                ...prev,
                                title: e.target.value,
                              };
                            })
                          }
                          value={newMenuItem.title}
                          name="title"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Step Link"
                          required
                          // style={{ width: "48%" }}
                          onChange={(e) =>
                            setNewMenuItem((prev) => {
                              return {
                                ...prev,
                                link: e.target.value,
                              };
                            })
                          }
                          value={newMenuItem.link}
                          name="title"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {(newMenuItem.blob || newMenuItem.image) && (
                      <img
                        style={{
                          width: "17rem",
                          height: "9rem",
                          // marginLeft: "27rem",
                          marginTop: "1rem",
                        }}
                        src={
                          newMenuItem.blob
                            ? newMenuItem.blob
                            : s3baseUrl + newMenuItem.image
                        }
                        alt="svg"
                      />
                    )}
                    <label htmlFor="icon-button-file">
                      <input
                        style={{ display: "none" }}
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        value=""
                        name="logo"
                        onChange={(e) =>
                          setNewMenuItem((prev) => {
                            return {
                              ...prev,
                              file: e.target.files[0],
                              blob: URL.createObjectURL(e.target.files[0]),
                            };
                          })
                        }
                      />
                      <Button
                        aria-label="upload picture"
                        component="span"
                        className="upload-button mt-3 "
                      >
                        {newMenuItem.image || newMenuItem.blob
                          ? "Change Step Image"
                          : "Upload Step Image"}
                      </Button>
                    </label>
                  </Grid>
                  <div className="text-end mt-3">
                    <Button
                      variant="contained"
                      type="submit"
                      // onClick={(e) => HandleEditData(e)}
                    >
                      {isEditing ? "Edit" : "Add"}
                    </Button>
                  </div>
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
      <MuiDialog
        open={deleteLogoDialog}
        onToggle={closeDelete}
        title="Delete Step"
        message="Are you sure you want to delete this Step?"
        onAgree={confirmDelete}
      />
    </Page>
  );
}

export default StartProjects;
