import { LoadingButton } from "@mui/lab";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { addMobileApp, getLandingPage } from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import { CircularLoader, Page, RichEditor } from "src/components";
import { s3baseUrl } from "src/config/config";

function MobileApp() {
  const { enqueueSnackbar } = useSnackbar();
  const [mobileAppData, setMobileAppData] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setMobileAppData({
          ...result.data.mobile_app,
          file: null,
          blob: "",
          androidFile: null,
          androidBlob: "",
          iosFile: null,
          iosBlob: "",
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = mobileAppData;
      if (data.file) {
        const formData = new FormData();
        formData.append("directory", "images/");
        formData.append("upload_file", data.file);
        const imgResult = await _upload_file(formData);
        if (imgResult.code == 200) {
          data["image"] = imgResult.path;
        }
      }
      if (data.androidFile) {
        const formDataAndroid = new FormData();
        formDataAndroid.append("directory", "images/");
        formDataAndroid.append("upload_file", data.androidFile);
        const imgResultAndroid = await _upload_file(formDataAndroid);
        if (imgResultAndroid.code == 200) {
          data["android_image"] = imgResultAndroid.path;
        }
      }
      if (data.iosFile) {
        const formDataIos = new FormData();
        formDataIos.append("directory", "images/");
        formDataIos.append("upload_file", data.iosFile);
        const imgResultIos = await _upload_file(formDataIos);
        if (imgResultIos.code == 200) {
          data["ios_image"] = imgResultIos.path;
        }
      }
      delete data.blob;
      delete data.file;
      delete data.androidBlob;
      delete data.androidFile;
      delete data.iosBlob;
      delete data.iosFile;
      const result = await addMobileApp(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setMobileAppData(result.data.mobile_app);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Mobile App">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Mobile App</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichEditor
                value={mobileAppData.title}
                setValue={(val) =>
                  setMobileAppData((prev) => {
                    return { ...prev, title: val };
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              {(mobileAppData.blob || mobileAppData.image) && (
                <img
                  style={{
                    width: "17rem",
                    height: "9rem",
                    // marginLeft: "27rem",
                    marginTop: "1rem",
                  }}
                  src={
                    mobileAppData.blob
                      ? mobileAppData.blob
                      : s3baseUrl + mobileAppData.image
                  }
                  alt="svg"
                />
              )}
              <label htmlFor="icon-button-file">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setMobileAppData((prev) => {
                      return {
                        ...prev,
                        file: e.target.files[0],
                        blob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {mobileAppData.image || mobileAppData.blob
                    ? "Change Image"
                    : "Upload Image"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              {(mobileAppData.androidBlob || mobileAppData.android_image) && (
                <img
                  style={{
                    width: "6rem",
                    height: "3rem",
                    // marginLeft: "27rem",
                    marginTop: "1rem",
                  }}
                  src={
                    mobileAppData.androidBlob
                      ? mobileAppData.androidBlob
                      : s3baseUrl + mobileAppData.android_image
                  }
                  alt="svg"
                />
              )}
              <label htmlFor="icon-button-file-android">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file-android"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setMobileAppData((prev) => {
                      return {
                        ...prev,
                        androidFile: e.target.files[0],
                        androidBlob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {mobileAppData.android_image || mobileAppData.androidBlob
                    ? "Change Android Image"
                    : "Upload Android Image"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              {(mobileAppData.iosBlob || mobileAppData.ios_image) && (
                <img
                  style={{
                    width: "6rem",
                    height: "3rem",
                    // marginLeft: "27rem",
                    marginTop: "1rem",
                  }}
                  src={
                    mobileAppData.iosBlob
                      ? mobileAppData.iosBlob
                      : s3baseUrl + mobileAppData.ios_image
                  }
                  alt="svg"
                />
              )}
              <label htmlFor="icon-button-file-ios">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file-ios"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setMobileAppData((prev) => {
                      return {
                        ...prev,
                        iosFile: e.target.files[0],
                        iosBlob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {mobileAppData.ios_image || mobileAppData.iosBlob
                    ? "Change IOS Image"
                    : "Upload IOS Image"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end pb-4">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}

export default MobileApp;
