import { _upload_file } from "src/DAL/uploads";

export { fDate, fDateTime, fDateTimeSuffix, fToNow } from "./formatTime";
export {
  fCurrency,
  fPercent,
  fNumber,
  fShortenNumber,
  fData,
} from "./formatNumber";
export { invokeApi } from "./invokeApi";
export {
  set_to_localStorage,
  get_from_localStorage,
  del_from_local_storage,
  //   logout_user,
} from "./localStorage";
// export { }from './'
// export { }from './'
// export { }from './'
// export { }from './'
// export { }from './'
export const upload_image = async (file) => {
  if (!file) {
    console.error("No File Provided");
    return;
  }
  if (file) {
    // const { type } = file;
    // let valid_type = false;
    // console.log(file, "target-file");
    // ALLOWED_IMAGE_TYPES.map((item, i) => {
    //   if (item === String(type)) {
    //     valid_type = true;
    //   }
    // });
    // if (valid_type === false) {
    //   console.error("invalid image type");
    //   return { code: 400, message: "Invalid file type" };
    // }
    const data = new FormData();
    data.append("directory", "images/");
    data.append("upload_file", file);
    const result = await _upload_file(data);
    return result;
  }
};
