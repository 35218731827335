// PaymentPlanCard.jsx
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Iconify } from "src/components";
import { Waves, WavesTop } from "src/assets";
import { LoadingButton } from "@mui/lab";

const PaymentPlanCard = ({ user, onClick, plan, buttonLoading, isActiveCard }) => {
  // console.log(user, "ascsiocasucilasc", plan);
  const getButtonText = (user, plan) => {
    if (
      isActiveCard
    ) {
      return "Current Plan";
    }
    else {
      return "Select plan";
    }
  };

  return (
    <div
      className="price-card pb-5"
      style={{
        backgroundImage: `url(${Waves})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        borderRadius: "5px !important",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
        height: "100%",
      }}
    >
      <div
        className="price-text mx-auto"
        style={{
          backgroundImage: `url(${WavesTop})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          //   backgroundPosition: "top",
          textAlign: "center",
          color: "white",
        }}
      >
        <h3 style={{ height: "70px" }} className="pt-1">
          <span>$</span>
          {plan.price}
        </h3>
      </div>
      <div className="px-3">
        <h2 className="text-center">{plan.name}</h2>
        <ul
          className="list-unstyled mt-4 text-center text-lg-start"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <li className="d-flex justify-content-between align-items-center">
            <span>Dashboard Analytic</span>
            {/* <i
            id="fa-solid"
            className={
              plan.dashboard_analytic.toLowerCase() == "yes"
                ? "fa-solid fa-check me-2"
                : "fa-solid fa-xmark me-2"
            }
          /> */}
            {plan.dashboard_analytic}
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Google Calendar Attachment</span>
            {/* <i
            id="fa-solid"
            className={
              plan.google_calendar_attachment.toLowerCase() == "yes"
                ? "fa-solid fa-check me-2"
                : "fa-solid fa-xmark me-2"
            }
          /> */}
            {plan.google_calendar_attachment}
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Workspaces Allowed</span>
            <span>{plan.workspace_allow}</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            {/* <i id="fa-solid" className="fa-solid fa-xmark me-2" />{" "} */}
            <span>Projects Allowed</span>
            <span>{plan.project_allow}</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Storage Allowed</span>
            <span>{plan.storage_allow}</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Team / Managers Allowed</span>
            <span>{plan.team_member_allow}</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Real-Time Chat</span>
            {/* <i
            id="fa-solid"
            className={
              plan.real_time_chat.toLowerCase() == "yes"
                ? "fa-solid fa-check me-2"
                : "fa-solid fa-xmark me-2"
            }
          /> */}
            {plan.real_time_chat}
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Email Support</span>
            {/* {plan.email_support.toLowerCase() == "no" ? (
            <i id="fa-solid" className="fa-solid fa-xmark me-2" />
          ) : (
            <span>{plan.email_support}</span>
          )} */}
            {plan.email_support}
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Clients Allowed</span>
            <span>{plan.client_allow}</span>
          </li>
        </ul>
        <div className="buy-btn d-flex justify-content-center">
          <LoadingButton
            variant="contained"
            disabled={
              getButtonText(user, plan) == "Cannot downgrade" ||
              getButtonText(user, plan) == "Current Plan"
            }
            loading={buttonLoading == plan.subscription_plan_name}
            onClick={() => onClick(plan, user)}
          >
            {getButtonText(user, plan)}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlanCard;
