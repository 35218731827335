import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { getLandingPage, landingPageSettings } from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import { CircularLoader, Page, RichEditor } from "src/components";
import { s3baseUrl } from "src/config/config";

function LandingPageSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [settingsData, setSettingsData] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const handleChange = (e) => {
    setSettingsData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setSettingsData({
          ...result.data.website_setting,
          file: null,
          blob: "",
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = settingsData;
      if (settingsData.file) {
        const formData = new FormData();
        formData.append("directory", "images/");
        formData.append("upload_file", settingsData.file);
        const imgResult = await _upload_file(formData);
        if (imgResult.code == 200) {
          data["brand_favicon"] = imgResult.path;
        }
      }
      delete data.file;
      delete data.blob;
      const result = await landingPageSettings(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setSettingsData(result.data.website_setting);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Landing Page Setting">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Settings</Typography>
        </Stack>
        <form action="" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Slug"
                  name="slug"
                  value={settingsData.slug}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Meta Title"
                  name="meta_title"
                  value={settingsData.meta_title}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Meta Keywords"
                  name="meta_keywords"
                  value={settingsData.meta_keywords}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Meta Description"
                  name="meta_description"
                  value={settingsData.meta_description}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Copyright text"
                  name="copy_right_text"
                  value={settingsData.copy_right_text}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Privacy Policy text"
                  value={settingsData.privacy_and_policy.text}
                  onChange={(e) => {
                    setSettingsData((prev) => {
                      return {
                        ...prev,
                        privacy_and_policy: {
                          ...prev.privacy_and_policy,
                          text: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Privacy Policy link"
                  value={settingsData.privacy_and_policy.link}
                  onChange={(e) => {
                    setSettingsData((prev) => {
                      return {
                        ...prev,
                        privacy_and_policy: {
                          ...prev.privacy_and_policy,
                          link: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Terms and Conditions text"
                  value={settingsData.terms_and_conditions.text}
                  onChange={(e) => {
                    setSettingsData((prev) => {
                      return {
                        ...prev,
                        terms_and_conditions: {
                          ...prev.terms_and_conditions,
                          text: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Terms and Conditions link"
                  value={settingsData.terms_and_conditions.link}
                  onChange={(e) => {
                    setSettingsData((prev) => {
                      return {
                        ...prev,
                        terms_and_conditions: {
                          ...prev.terms_and_conditions,
                          link: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="cookies and preferences text"
                  value={settingsData.cookies_and_preferences.text}
                  onChange={(e) => {
                    setSettingsData((prev) => {
                      return {
                        ...prev,
                        cookies_and_preferences: {
                          ...prev.cookies_and_preferences,
                          text: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="cookies and preferences link"
                  value={settingsData.cookies_and_preferences.link}
                  onChange={(e) => {
                    setSettingsData((prev) => {
                      return {
                        ...prev,
                        cookies_and_preferences: {
                          ...prev.cookies_and_preferences,
                          link: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <RichEditor
                value={settingsData.payment_plan_heading}
                setValue={(val) =>
                  setSettingsData((prev) => {
                    return { ...prev, payment_plan_heading: val };
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              {(settingsData.blob || settingsData.brand_favicon) && (
                <img
                  style={{
                    width: "17rem",
                    height: "9rem",
                    // marginLeft: "27rem",
                    marginTop: "1rem",
                  }}
                  src={
                    settingsData.blob
                      ? settingsData.blob
                      : s3baseUrl + settingsData.brand_favicon
                  }
                  alt="preview"
                />
              )}

              <label htmlFor="icon-button-file">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setSettingsData((prev) => {
                      return {
                        ...prev,
                        file: e.target.files[0],
                        blob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {settingsData.brand_favicon !== ""
                    ? "Change Favicon"
                    : "Upload Favicon"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end mt-3">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Update
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}

export default LandingPageSettings;
