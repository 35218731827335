import PropTypes from "prop-types";
// material
import { TableRow, TableCell, TableHead, Typography } from "@mui/material";

// ----------------------------------------------------------------------

TableCustomHead.propTypes = {
  headLabel: PropTypes.array,
};

export default function TableCustomHead({ headLabel }) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
          >
            <Typography variant="subtitle" fontWeight={"bold"}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
