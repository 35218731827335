import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useLayoutEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { country_list } from "src/constants/constants";
// material
import { autocompleteClasses, Avatar, AvatarGroup, Backdrop, Badge, BottomNavigation, BottomNavigationAction, Box, Breadcrumbs, Button, ButtonBase, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Chip, CircularProgress, Collapse, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, Fab, Fade, FilledInput, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Grow, Hidden, Icon, IconButton, ImageList, ImageListItem, ImageListItemBar, Input, InputAdornment, InputBase, InputLabel, LinearProgress, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem, MenuList, MobileStepper, Modal, NativeSelect, OutlinedInput, Pagination, PaginationItem, Paper, Popover, Popper, Portal, Radio, RadioGroup, Rating, ScopedCssBaseline, Select, Skeleton, Slide, Slider, Snackbar, SnackbarContent, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, Step, StepButton, StepConnector, StepContent, StepIcon, StepLabel, Stepper, SvgIcon, SwipeableDrawer, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tabs, TextField, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography, Zoom } from '@mui/material';

// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import { MuiDialog } from "src/components";
import TableCustomHead from "src/components/TableCustomHead";
import { _edit_user, _add_user, _delete_user } from "src/DAL/users";
// mock
// import USERLIST from "../../_mock/user";
//
import { useNavigate } from "react-router-dom";
import TableToolbar from "src/components/TableToolbar";
import MoreMenu from "../../components/MoreMenu";
import { _get_users_list } from "src/DAL/users";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import MuiDrawer from "src/components/MuiDrawer";
import { Password } from "@mui/icons-material";
import CustomDrawer from "src/components/CustomDrawer";
import CustomModal from "src/components/CustomModal";
import CountryPicker from "src/components/CountryPicker";
import { _upload_file } from "src/DAL/uploads";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#" },
  { id: "name", label: "Name", alignRight: false },
  { id: "contact_number", label: "Contact Number", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "plan", label: "Plan", alignRight: false },
  { id: "subscription", label: "Subscription", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "updatedAt", label: "Last Updated", alignRight: false },
  { id: "action", label: "Actions", alignRight: false },
];

// ----------------------------------------------------------------------

function applySortFilter(array, query) {
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return array;
}

function getCountryLabelByCode(code) {
  return (
    country_list.find((country) => country.phone === code.replace("+", ""))?.label ??
    country_list[0].label
  );
}


const initialFormData = {
  user_id: '',
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  subscription_status: "active",
  status: true,
  contact_number: "",
  file: '',
  country_code: '+92',
  country: country_list.find(
    (country) => country.phone == "92"
  ) ?? country_list[0],
};

export default function Users() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [userList, setUserList] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [detailUserData, setDetailUserData] = useState();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deletedItem, setDeletedItem] = useState();
  const [passwordData, setPasswordData] = useState(

  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        file: file,
      }));
    }
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData, "Add user Data");
    try {
      const form_data = new FormData();
      form_data.append('first_name', formData?.first_name);
      form_data.append('last_name', formData?.last_name);
      form_data.append('contact_number', formData?.contact_number)
      form_data.append('country_code', formData?.country_code);
      form_data.append('gender', "male");
      form_data.append('status', formData.status);
      if (isEditingUser) {
        form_data.append("user_id", formData?.user_id);
      } else {
        form_data.append('email', formData?.email);
        form_data.append('password', formData?.password);
        form_data.append("device_token", "");
      }
      let imgPath;
      if (!formData?.file) {
        enqueueSnackbar('Image is required', { variant: 'error' });
        return;
      } else {
        if (formData.file instanceof File) {
          const myData = new FormData();
          myData.append("directory", "images/");
          myData.append("upload_file", formData.file);
          const res = await _upload_file(myData);
          if (res.code == 200) {
            imgPath = res.path;
          } else {
            enqueueSnackbar(res.message, { variant: 'error' });
          }
        }
        else {
          imgPath = formData.file;
        }
      }
      form_data.append("image", imgPath);
      console.log('...........FormData .............. \n' + form_data);

      const result = isEditingUser ? await _edit_user(form_data) : await _add_user(form_data);
      if (result.code === 200) {
        console.log(result, 'ddd');
        if (!isEditingUser) {
          setUserList((prev) => [
            result.customer,
            ...prev,
          ]);
        } else {
          setUserList((prev) => {
            return prev.map((item) => {
              if (item.user_id == result.customer.user_id) {
                return result.customer;
              } else {
                return item;
              }
            })
          })
        }

        enqueueSnackbar(result.message, { variant: 'success' });
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
      }
      setIsAddDrawerOpen(false);
    } catch (error) {
      console.error('Error during form submission:', error);
      enqueueSnackbar('An error occurred during submission', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordFormSubmit = () => {
    console.log("Password ");
  }

  const getCode = () => {
    if (formData?.country_code) {
      return `${formData.country_code}`;
    }
    return "";
  };

  const handleAgreeDeleteUser = async () => {
    console.log(deletedItem.user_id);

    try {
      const form_data = new FormData();
      form_data.append('customer_user_id', deletedItem.user_id);
      console.log('...........FormData .............. \n' + form_data);

      const result = await _delete_user(form_data);
      if (result.code === 200) {
        setUserList((prev) => {
          return prev.filter((item) => {
            return item.user_id !== deletedItem.user_id;
          })
        })
        enqueueSnackbar(result.message, { variant: 'success' });
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      enqueueSnackbar('An error occurred during submission', { variant: 'error' });
    }
    setOpenDeleteConfirmation(false);
  }

  const MENU_OPTIONS = [
    {
      label: "User Detail",
      icon: "mdi:account",
      onClick: (item) => {
        setDetailUserData(item);
        setIsDetailModalOpen(true);
      },
    },
    {
      label: "Edit User",
      icon: "mdi:account-edit",
      onClick: (item) => {
        console.log(item)
        setIsEditingUser(true);
        const myData = {
          user_id: item.user_id,
          last_name: item.last_name,
          first_name: item.first_name,
          email: item.email,
          contact_number: item.contact_number,
          status: item.status,
          // password: item,
          // status: item.subscription_status,
          // plan: item.subscription,
          file: item.image,
          country_code: "+" + country_list.find(
            (country) => "+" + country.phone === item.country_code
          )?.phone ?? country_list[0]?.phone,

          country: country_list.find(
            (country) => "+" + country.phone == item.country_code
          ) ?? country_list[0],
        };
        console.log(myData);
        setFormData(myData);
        setIsAddDrawerOpen(true);
      },
    },
    // {
    //   label: "Update Password",
    //   icon: "mdi:lock-reset",
    //   onClick: (item) => {
    //     setIsUpdatePassword(true);
    //   },
    // },
    {
      label: "Edit Plan",
      icon: "mdi:calendar-edit",
      onClick: (item) => {
        navigate("/edit-user-plan", { state: { ...item } });
      },
    },
    {
      label: "Delete User",
      icon: "mdi:delete",
      onClick: (item) => {
        setDeletedItem(item);
        console.log(item);
        setOpenDeleteConfirmation(true);
      },
    },
  ];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = userList;
  // const filteredUsers = applySortFilter(userList, filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const getUsersList = async () => {
    setPageLoading(true);
    try {
      const result = await _get_users_list(page, rowsPerPage, {
        search: filterName,
      });
      if (result.code == 200) {
        setUserList(result.subscriber_list.all_subscribers);
        setUserCount(result.subscriber_list.all_subscribers_count);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error, " catched while fetching user list");
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    setUserList([]);
    setPageLoading(true);
    getUsersList();
  }, [page, filterName]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, filterName]);


  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Users</Typography>
          <Button variant="contained" onClick={() => {
            setIsEditingUser(false);
            setFormData({ ...initialFormData });
            setIsAddDrawerOpen(true);
          }}>
            Add New User
          </Button>
        </Stack>
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Search Users..."
          />
          <Scrollbar>
            <TableContainer sx={{ overflow: "auto" }}>
              <Table>
                <TableCustomHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {userList
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        user_id,
                        first_name,
                        last_name,
                        email,
                        contact_number,
                        image,
                        subscription_status,
                        subscription,
                        status,
                        gender,
                        createdAt,
                        updatedAt,
                      } = row;
                      return (
                        <TableRow hover key={user_id}>
                          <TableCell align="left">{(page * rowsPerPage) + index + 1}</TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={first_name}
                                src={image ? s3baseUrl + image : ""}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {first_name + " " + last_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{contact_number ? contact_number : "---------"}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={status ? "success" : "error" }
                            >
                              {sentenceCase(
                                status
                                  ? "Active"
                                  : "Inactive"
                              )}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            {subscription
                              ? subscription
                              : "Free Plan"}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={
                                ((subscription_status === "deleted" ||
                                  subscription_status === "false" ||
                                  subscription_status === "canceled") &&
                                  "error") ||
                                (subscription_status === "requires_action" &&
                                  "warning") ||
                                "success"
                              }
                            >
                              {sentenceCase(
                                subscription_status
                                  ? subscription_status
                                  : "active"
                              )}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{moment(createdAt).format("LLL")}</TableCell>
                          <TableCell align="left">{moment(updatedAt).format("LLL")}</TableCell>
                          <TableCell align="left">
                            <MoreMenu row={row} menuOptions={MENU_OPTIONS} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {pageLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {isUserNotFound && !pageLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={userCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <CustomDrawer
          title={isEditingUser ? "Edit User" : "Add User"}
          onClose={() => setIsAddDrawerOpen(false)}
          open={isAddDrawerOpen}
        >
          <form onSubmit={handleFormSubmit} autoComplete="off">
            <Stack spacing={2} sx={{ px: 3, pt: 1 }}>
              <div className="row">
                <div className="col">
                  <TextField
                    label="First Name"
                    name="first_name"
                    required
                    placeholder="First Name"
                    value={formData?.first_name}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </div>
                <div className="col">
                  <TextField
                    label="Last Name"
                    name="last_name"
                    required
                    placeholder="Last Name"
                    value={formData?.last_name}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </div>

              </div>
              <TextField
                label="Email"
                autoComplete="off"
                placeholder="Type Your Email"
                name="email"
                type="email"
                required
                value={formData?.email}
                disabled={isEditingUser}
                onChange={handleInputChange}
                fullWidth
              />
              {!isEditingUser &&
                <TextField
                  label="Password"
                  name="password"
                  placeholder="********"
                  type="Password"
                  autoComplete="new-password"
                  required
                  value={formData?.password}
                  onChange={handleInputChange}
                  fullWidth
                />}
              <CountryPicker
                country={formData?.country}
                setFormInputs={setFormData}
              />
              <div className="row">
                <div className="col-4 m-0 p-0 pe-1">
                  <TextField
                    value={getCode()}
                    label="Country code"
                    required
                    disabled
                    fullWidth
                    className=""
                  />
                </div>
                <div className="col-8 m-0 p-0 ps-3">
                  <TextField
                    label="Contact Number"
                    name="contact_number"
                    placeholder="3001122333"
                    type="tel"
                    required
                    value={formData?.contact_number}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </div>
              </div>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ border: '1px solid gray', p: 2, height: '100px' }}
              >
                {formData?.file ? (
                  <img
                    src={
                      formData?.file instanceof File
                        ? URL.createObjectURL(formData?.file)
                        : s3baseUrl + formData?.file
                    }
                    alt="Icon Preview"
                    style={{ height: '100%', width: 'auto', marginBottom: '8px' }}
                  />
                ) : (
                  <Typography variant="body2" className="my-auto">
                    No Image Selected
                  </Typography>
                )}
              </Box>

              {formData?.file ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setFormData((prevData) => ({ ...prevData, file: null }));
                    // setIsUpdateImage(true);
                  }}
                  fullWidth
                >
                  Remove Image
                </Button>
              ) : (
                <Button variant="outlined" color="inherit" component="label" fullWidth>
                  Add Image *
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={handleImageChange}
                    hidden
                  />
                </Button>
              )}
              <Box display="flex" alignItems="center" justifyContent="flex-end" className="w-100">
                <Typography variant="body1" mr={2}>
                  {"Status"} 
                  {formData.status == true ? <> Active&nbsp; </> : <> Inactive </> }
                </Typography>
                <FormControlLabel
                className="m-0 p-0"
                  control={
                    <Switch
                    checked={formData.status}
                    onChange={(e) => {
                      setFormData((prev) => {
                        return { ...prev, status: e.target.checked };
                      })
                    }}
                  />
                  }
                />
              </Box>
              <Stack direction="row" spacing={1} justifyContent="flex-end" className="mb-2">
                <Button variant="outlined" color="inherit" onClick={() => setIsAddDrawerOpen(false)}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </CustomDrawer>
        <CustomDrawer
          title="Update Password"
          onClose={() => { setIsUpdatePassword(false) }}
          open={isUpdatePassword}
        >
          <form onSubmit={handlePasswordFormSubmit} autoComplete="off">
            <Stack spacing={3} sx={{ px: 3, pt: 1 }}>
              <TextField
                label="Old Password"
                name="old_password"
                placeholder="********"
                type="Password"
                autoComplete="new-password"
                required
                value={formData?.password}
                onChange={handlePasswordChange}
                fullWidth
              />
              <TextField
                label="New Password"
                name="old_password"
                placeholder="********"
                type="Password"
                autoComplete="new-password"
                required
                value={formData?.password}
                onChange={handlePasswordChange}
                fullWidth
              />
              <TextField
                label="Re-Enter New Password"
                name="old_password"
                placeholder="********"
                type="Password"
                autoComplete="new-password"
                required
                value={formData?.password}
                onChange={handlePasswordChange}
                fullWidth
              />
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Button variant="outlined" color="inherit" onClick={() => setIsUpdatePassword(false)}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Update
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </CustomDrawer>
        <CustomModal open={isDetailModalOpen} onClose={() => { setIsDetailModalOpen(false) }} title={"User Detail"} >
          <div className="">
            <Typography fontSize={16} fontWeight="600" variant="h6" className="mb-2">
              Personal Details
            </Typography>
            <div className="user-detail-modal">
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="900" >Name: </Typography></div>
                <div className="col-8"><Typography variant="body1">{(detailUserData?.first_name && detailUserData?.last_name) ? `${detailUserData.first_name} ${detailUserData.last_name}` : "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Email: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.email || "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Gender: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.gender || "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Country Code: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.country_code || "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Contact Number: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.contact_number || "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Created At: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.createdAt ? moment(detailUserData.createdAt).format("LLL") : "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Last Updated: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.updatedAt ? moment(detailUserData.updatedAt).format("LLL") : "N/A"}</Typography></div>
              </div>
            </div>

            <Typography fontSize={16} fontWeight="600" variant="h6" className="my-2">
              Plan Information
            </Typography>
            <div className="user-detail-modal">
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Subscription: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.subscription || "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Subscription ID: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.subscription_plan_id || "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Plan ID: </Typography></div>
                <div className="col-8"><Typography variant="body1">{detailUserData?.plan_id || "N/A"}</Typography></div>
              </div>
              <div className="row mb-2">
                <div className="col-4"><Typography variant="body1" fontweight="600" >Subscription Status: </Typography></div>
                <div className="col-8"><Typography variant="body1">
                  {detailUserData?.subscription_status ? <Label variant="outlined" color="success">
                    {sentenceCase(detailUserData?.subscription_status)}
                  </Label> : "N/A"}
                </Typography></div>
              </div>
            </div>
          </div>
        </CustomModal>
        <MuiDialog
          open={openDeleteConfirmation}
          onToggle={() => {
            setOpenDeleteConfirmation(false);
          }}
          title="Delete User"
          message={`Are you sure you want to delete this User "${deletedItem?.first_name + " " + deletedItem?.last_name}"?`}
          onAgree={handleAgreeDeleteUser}
        />
      </Container>
    </Page>
  );
}
