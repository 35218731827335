import { CircularProgress, Container, Grid, Typography, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { _change_user_plan, _get_user_detail } from "src/DAL/users";
import { Page } from "src/components";
import { Iconify } from "src/components";
import PaymentPlanCard from "./components/PaymentPlanCard";
import "./css/style.css"; // Your custom CSS
import { Waves } from "src/assets";
import { useSnackbar } from "notistack";

function EditUserPlan() {
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [userDetails, setUserDetails] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");
  const navigate = useNavigate();
  const getUserDetails = async () => {
    try {
      setPageLoading(true);
      const result = await _get_user_detail(state.user_id);
      if (result.code == 200) {
        setUserDetails(result);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        navigate(-1);
      }
    } catch (error) {
      console.log(error, " catched while fetching user details");
      enqueueSnackbar("Something went wrong", { variant: "error" });
    } finally {
      setPageLoading(false);
    }
  };
  const onButtonClick = async (plan, user) => {
    console.log(plan, user);

    try {
      setButtonLoading(plan.subscription_plan_name);
      const req_obj = {
        user_id: state.user_id,
        // plan_name: plan.subscription_plan_name,
        plan_id: plan._id,
      };
      const result = await _change_user_plan(req_obj);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        // getUserDetails();
        navigate(-1);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      console.log(error, " occured while changing user plan");
    } finally {
      setButtonLoading("");
    }
  };
  useEffect(() => {
    if (!state) {
      navigate("/");
    } else {
      getUserDetails();
    }
  }, []);
  return (
    <Page title="Edit User Plan">
      {pageLoading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <CircularProgress />
        </div>
      )}
      {!pageLoading && (
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="" mb={4.5}>
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="ic:round-arrow-back" />
            </IconButton>
            <Typography variant="h4">Update user subscription</Typography>
          </Stack>
          <Grid container spacing={2} sx={{ mt: 4 }}>
            {userDetails?.all_plans.map((plan) => (
              <Grid item xs={12} sm={6} md={4} key={plan._id}>
                <PaymentPlanCard
                  plan={plan}
                  onClick={onButtonClick}
                  user={userDetails.customer}
                  buttonLoading={buttonLoading}
                  isActiveCard={userDetails?.current_plans?.plan_id == plan._id}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </Page>
  );
}

export default EditUserPlan;
