import { invokeApi } from "src/utils";
export const getLandingPage = () => {
  const reqData = {
    method: "GET",
    path: "api/landing_page/landing_page_detail",
    headers: { "x-sh-auth": localStorage.getItem("token") },
  };
  return invokeApi(reqData);
};

export const addHeader = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_header",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const landingPageSettings = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/website_setting",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addBanner = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_banner",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addBrandLogo = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_brand_logo",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addReadySolutions = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_ready_solutions",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addCatchup = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_catch_up",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addDriveEfficiency = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_drive_efficiency",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addFooter = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_footer",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addPowerfulWork = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_powerful_work",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addMobileApp = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_mobile_app",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addStartProjects = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_start_projects",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addStories = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_stories",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addSubscriberData = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_subscriber_data",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addTransformTheWay = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_transform_the_way",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};

export const addWorkManagement = (data) => {
  const reqData = {
    method: "POST",
    path: "api/landing_page/add_work_management",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(reqData);
};
