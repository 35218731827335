import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { addFooter, getLandingPage } from "src/DAL/landingPage";
import { _upload_file } from "src/DAL/uploads";
import {
  CircularLoader,
  Iconify,
  MuiDialog,
  Page,
  RichEditor,
} from "src/components";
import { s3baseUrl } from "src/config/config";
function Footer() {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [footerData, setFooterData] = useState();
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [addMenuitemDialogOpen, setAddMenuitemDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [newMenuItem, setNewMenuItem] = useState({
    image: "",
    file: null,
    blob: "",
    link: "",
  });
  const [deleteLogoDialog, setDeleteLogoDialog] = useState(false);
  const openDelete = () => {
    setDeleteLogoDialog(true);
  };
  const closeDelete = () => {
    setDeleteLogoDialog(false);
    setEditIndex();
  };
  const confirmDelete = () => {
    setFooterData((prev) => {
      return {
        ...prev,
        menu_items: prev.menu_items.filter((item, i) => i !== editIndex),
      };
    });
    closeDelete();
  };
  const openAddItemDialog = () => {
    setAddMenuitemDialogOpen(true);
  };
  const closeAddItemDialog = () => {
    setAddMenuitemDialogOpen(false);
    setNewMenuItem({
      image: "",
      file: null,
      blob: "",
      link: "",
    });
    setIsEditing(false);
    setEditIndex();
  };
  const getLandingPageData = async () => {
    try {
      const result = await getLandingPage();
      if (result.code == 200) {
        setFooterData({
          ...result.data.footer,
          androidFile: null,
          iosFile: null,
          androidBlob: "",
          iosBlob: "",
          menu_items: result.data.footer.menu_items.map((item) => {
            return { ...item, file: null, blob: "" };
          }),
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddData = () => {
    setFooterData((prev) => {
      return {
        ...prev,
        menu_items: [...prev.menu_items, newMenuItem],
      };
    });
    closeAddItemDialog();
  };
  const handleEditData = () => {
    setFooterData((prev) => {
      let temp_menu = prev.menu_items;
      temp_menu[editIndex] = newMenuItem;
      console.log(temp_menu, "fdfufuyu");
      return {
        ...prev,
        menu_items: [...temp_menu],
      };
    });
    closeAddItemDialog();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingBtn(true);
      let data = footerData;
      let logos = data.menu_items;
      for (let i = 0; i < logos.length; i++) {
        if (logos[i].file) {
          const formData = new FormData();
          formData.append("directory", "images/");
          formData.append("upload_file", logos[i].file);
          const imgResult = await _upload_file(formData);
          if (imgResult.code == 200) {
            logos[i]["image"] = imgResult.path;
          }
        }
        delete logos[i].file;
        delete logos[i].blob;
        if (logos[i]._id) {
          delete logos[i]._id;
        }
      }
      if (data.androidFile) {
        const formData = new FormData();
        formData.append("directory", "images/");
        formData.append("upload_file", data.androidFile);
        const imgResult = await _upload_file(formData);
        if (imgResult.code == 200) {
          data["android_app_store_image"] = imgResult.path;
        }
      }
      if (data.iosFile) {
        const formData = new FormData();
        formData.append("directory", "images/");
        formData.append("upload_file", data.iosFile);
        const imgResult = await _upload_file(formData);
        if (imgResult.code == 200) {
          data["ios_app_store_image"] = imgResult.path;
        }
      }

      data["menu_items"] = logos;
      delete data.androidBlob;
      delete data.androidFile;
      delete data.iosBlob;
      delete data.iosFile;
      const result = await addFooter(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setFooterData(result.data.footer);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    getLandingPageData();
  }, []);
  if (loading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Footer">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          <Typography variant="h4">Footer</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Android app store link"
                  value={footerData.android_app_store_link}
                  onChange={(e) =>
                    setFooterData((prev) => {
                      return {
                        ...prev,
                        android_app_store_link: e.target.value,
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {(footerData.androidBlob ||
                footerData.android_app_store_image) && (
                <img
                  style={{
                    width: "6rem",
                    height: "3rem",
                    // marginLeft: "27rem",
                    // marginTop: "1rem",
                  }}
                  src={
                    footerData.androidBlob
                      ? footerData.androidBlob
                      : s3baseUrl + footerData.android_app_store_image
                  }
                  alt="svg"
                />
              )}
              <label htmlFor="icon-button-file-android">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file-android"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setFooterData((prev) => {
                      return {
                        ...prev,
                        androidFile: e.target.files[0],
                        androidBlob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {footerData.android_app_store_image || footerData.androidBlob
                    ? "Change Android Image"
                    : "Upload Android Image"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="IOS app store link"
                  value={footerData.ios_app_store_link}
                  onChange={(e) =>
                    setFooterData((prev) => {
                      return {
                        ...prev,
                        ios_app_store_link: e.target.value,
                      };
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {(footerData.iosBlob || footerData.ios_app_store_image) && (
                <img
                  style={{
                    width: "6rem",
                    height: "3rem",
                    // marginLeft: "27rem",
                    // marginTop: "1rem",
                  }}
                  src={
                    footerData.iosBlob
                      ? footerData.iosBlob
                      : s3baseUrl + footerData.ios_app_store_image
                  }
                  alt="svg"
                />
              )}
              <label htmlFor="icon-button-file-ios">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file-ios"
                  type="file"
                  value=""
                  name="logo"
                  onChange={(e) =>
                    setFooterData((prev) => {
                      return {
                        ...prev,
                        iosFile: e.target.files[0],
                        iosBlob: URL.createObjectURL(e.target.files[0]),
                      };
                    })
                  }
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  className="upload-button mt-3 "
                >
                  {footerData.ios_app_store_image || footerData.iosBlob
                    ? "Change IOS Image"
                    : "Upload IOS Image"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} mt={5}>
              <Button onClick={openAddItemDialog}>Add Menu Item</Button>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>image</TableCell>
                    <TableCell>link</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {footerData?.menu_items?.map((val, i) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell>
                            <img
                              style={{ width: "4.5rem", height: "2.5rem" }}
                              src={val.blob ? val.blob : s3baseUrl + val.image}
                              alt=""
                            />
                          </TableCell>
                          <TableCell>{val.link}</TableCell>
                          <TableCell>
                            <Iconify
                              onClick={() => {
                                setIsEditing(true);
                                setEditIndex(i);
                                setNewMenuItem(val);
                                openAddItemDialog();
                              }}
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                            />
                          </TableCell>
                          <TableCell>
                            <Iconify
                              onClick={() => {
                                setEditIndex(i);
                                openDelete();
                              }}
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <div className="text-end pb-4">
                <LoadingButton
                  loading={loadingBtn}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Dialog
        open={addMenuitemDialogOpen}
        onClose={closeAddItemDialog}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>{isEditing ? "Edit Menu Item" : "Add Menu Item"}</p>
            <p onClick={closeAddItemDialog}>
              <Iconify icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form
                  className="gap-3 mt-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (isEditing) {
                      handleEditData();
                    } else {
                      handleAddData();
                    }
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Link"
                          required
                          // style={{ width: "48%" }}
                          onChange={(e) =>
                            setNewMenuItem((prev) => {
                              return {
                                ...prev,
                                link: e.target.value,
                              };
                            })
                          }
                          value={newMenuItem.link}
                          name="title"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {(newMenuItem.blob || newMenuItem.image) && (
                      <img
                        style={{
                          width: "17rem",
                          height: "9rem",
                          // marginLeft: "27rem",
                          marginTop: "1rem",
                        }}
                        src={
                          newMenuItem.blob
                            ? newMenuItem.blob
                            : s3baseUrl + newMenuItem.image
                        }
                        alt="svg"
                      />
                    )}
                    <label htmlFor="icon-button-file">
                      <input
                        style={{ display: "none" }}
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        value=""
                        name="logo"
                        onChange={(e) =>
                          setNewMenuItem((prev) => {
                            return {
                              ...prev,
                              file: e.target.files[0],
                              blob: URL.createObjectURL(e.target.files[0]),
                            };
                          })
                        }
                      />
                      <Button
                        aria-label="upload picture"
                        component="span"
                        className="upload-button mt-3 "
                      >
                        {newMenuItem.image || newMenuItem.blob
                          ? "Change Image"
                          : "Upload Image"}
                      </Button>
                    </label>
                  </Grid>
                  <div className="text-end mt-3">
                    <Button
                      variant="contained"
                      type="submit"
                      // onClick={(e) => HandleEditData(e)}
                    >
                      {isEditing ? "Edit" : "Add"}
                    </Button>
                  </div>
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
      <MuiDialog
        open={deleteLogoDialog}
        onToggle={closeDelete}
        title="Delete menu item"
        message="Are you sure you want to delete this menu item?"
        onAgree={confirmDelete}
      />
    </Page>
  );
}

export default Footer;
