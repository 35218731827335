import { Avatar, Backdrop, Badge, Box, Card, CircularProgress, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { Page, RichEditor, Iconify } from 'src/components';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { get_from_localStorage } from 'src/utils';
import { country_list, ALLOWED_IMAGE_TYPES } from 'src/constants/constants';
import { useSnackbar } from 'notistack';
import CountryPicker from 'src/components/CountryPicker';
import { _upload_file } from 'src/DAL/uploads';
import { _update_user_profile } from 'src/DAL/auth';
import { set_to_localStorage } from 'src/utils';
import { s3baseUrl } from 'src/config/config';
import { useAppContext } from 'src/hooks';


const Profile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const profile = get_from_localStorage("user");
  const {_set_user_profile} = useAppContext();
  const [file, setFile] = useState("");
  const [tempDP, setTempDP] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    first_name: profile.first_name,
    last_name: profile.last_name,
    email: profile.user_id.email,
    gender: profile.gender,
    contact_number: profile.contact_number,
    image: profile.profile_image,
    country_code: profile.country_code,
    country:
      country_list.find(
        (country) => country.phone == profile.country_code.replace("+", "")
      ) ?? country_list[0],
  });

  const getCode = () => {
    if (inputs.country?.phone) {
      return `+${inputs.country.phone}`;
    }
    return "";
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  }

  const handleFileChange = async (e) => {
    if (loading) {
      return;
    }
    let file = e.target.files[0];
    const { type, size } = file;
    console.log(file);
    if (size > 1048576) {
      enqueueSnackbar("Please select Image upto size of 1mb", {
        variant: "warning",
      });
      return;
    }
    let valid_type = false;
    ALLOWED_IMAGE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
        console.log("valid");
      }
    });
    if (!valid_type) {
      enqueueSnackbar("Please choose an image", { variant: "error" });
      return;
    }
    setFile(file);
    setTempDP(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let image_path = profile.profile_image;
    if(!file && !(inputs.image)){
      enqueueSnackbar("Image Is Required", { variant: "error" });
      return;
    }
    setLoading(true);
    if (file) {
      console.log(file);
      const data = new FormData();
      data.append("directory", "images/");
      data.append("upload_file", file);
      const result = await _upload_file(data);
      if (result.code === 200) {
        const { path } = result;
        image_path = path;
      } else {
        setLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
        return;
      }
    }
    let user_profile = {
      ...profile,
      ["first_name"]: inputs.first_name,
      ["last_name"]: inputs.last_name,
      ["contact_number"]: inputs.contact_number,
      ["profile_image"]: image_path,
    };
    
    let payload = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      contact_number: inputs.contact_number,
      profile_image: image_path,
    };
    const update_user_result = await _update_user_profile(
      payload
    );
    if (update_user_result.code === 200) {
      setLoading(false);
      set_to_localStorage("user", user_profile);
      _set_user_profile(user_profile);
      enqueueSnackbar("Profile Updated Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(update_user_result.message, { variant: "error" });
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Page title='Edit Profile'>
      <Container maxWidth='xl'>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="mb-4">
          <Typography variant="h4" gutterBottom>
            Edit Profile
          </Typography>
        </div>
        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            width: "100%",

            py: 10,
          }}
        >
          <Stack
            direction="row"
            spacing={0}
            justifyContent="center"
            sx={{ width: "80%", mx: "auto" }}
          >
            <Badge
              sx={{ m: "auto", mt: "30px" }}
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              invisible={false}
              badgeContent={
                <Avatar alt="upload image">
                  <IconButton
                    sx={{ fontSize: 20 }}
                    aria-label="upload picture"
                    component="label"
                  >
                    <ModeEditOutlineIcon />
                    <input
                      hidden
                      accept=".jpg,.png"
                      name="logo"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </IconButton>
                </Avatar>
              }
            >
              <Avatar
                alt={inputs?.full_name}
                variant="square"
                src={
                  tempDP
                    ? tempDP
                    : inputs.image
                      ? s3baseUrl + inputs.image
                      : ""
                }
                sx={{
                  width: 250,
                  height: 300,
                  bgcolor: "#e6ecff",
                  color: "#AEB4B7",
                }}
              />
            </Badge>
            <form autoComplete="off">
              <Box sx={{ width: "80%", mx: "auto" }} margin={0}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 0, sm: 0, md: 3 }}
                  mt={1}
                >
                  <Grid item md={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      // required
                      disabled
                      name="email"
                      value={inputs.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      required
                      name="first_name"
                      value={inputs.first_name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      // fullWidth
                      required
                      name="last_name"
                      value={inputs.last_name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CountryPicker
                      country={inputs.country}
                      setinputs={setInputs}
                    />
                  </Grid>
                  <Grid xs={4} item>
                    <TextField
                      value={getCode()}
                      label="Country code"
                      required
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      value={inputs.contact_number}
                      onChange={handleChange}
                      label="Phone number"
                      required
                      type="text"
                      variant="outlined"
                      name="contact_number"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Gender
                      </InputLabel>
                      <Select
                        label="Gender"
                        name="gender"
                        value={inputs?.gender || "male"}
                        onChange={handleChange}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Stack direction="row" spacing={2}>
                      <LoadingButton
                        loading={loading}
                        variant="outlined"
                        onClick={handleSubmit}
                      >
                        save
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Stack>
        </Card>
      </Container>
    </Page>
  );
};

export default Profile;
