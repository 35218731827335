import React, { useState, useEffect } from "react";
import { _notificationList } from "src/DAL/notifications";

const Context = React.createContext();
const AppContext = ({ children }) => {
  const [profile, setProfile] = useState(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {
          first_name: "",
          last_name: "",
          email: "",
          image: "",
        }
  );
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [badge, setBadge] = useState({
    chat: false,
    invitation: 0,
    support: 0,
  });
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState(0);
  const [keys, setKeys] = useState({ admin: "", client: "" });
  const [keyLoading, setKeyLoading] = useState(true);
  const getNotificationList = async (pg = 0) => {
    pg !== 0 && setLoading(true);
    const result = await _notificationList(pg);
    console.log(result, "notifications list");
    if (result.code == 200) {
      const temp = result.notification_list.notification.map((notification) => {
        return {
          ...notification,
          isUnRead: !notification.is_seen,
        };
      });
      setNotifications((prev) => {
        return pg ? prev.concat(temp) : temp;
      });
      setPage(pg + 1);
      setCount(result.notification_list.notification_count);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setLoading(false);
  };
  //------------------------------------------------------
  const _get_user_profile = () => {
    return profile;
  };
  const _set_user_profile = (value) => {
    setProfile(value);
  };

  //------------------------------------------------------
  useEffect(() => {
    //
    return () => {
      //cleanup
    };
  }, []);
  //------------------------------------------------------
  const bundle = {
    _get_user_profile,
    _set_user_profile,
    notificationCount,
    setNotificationCount,
    notifications,
    setNotifications,
    count,
    setCount,
    page,
    setPage,
    loading,
    setLoading,
    getNotificationList,
    unreadNotification,
    setUnreadNotification,
    badge,
    setBadge,
    keys,
    setKeys,
    keyLoading,
    setKeyLoading,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => React.useContext(Context);
