import { LoadingButton } from "@mui/lab";
import {
    Button,
    Card,
    CircularProgress,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import { capitalCase } from "change-case";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { _get_updates_list, _delete_update_item } from "src/DAL/manageUpdates";
import {
    CircularLoader,
    Iconify,
    Label,
    MuiDialog,
    Page,
    Scrollbar,
    SearchNotFound,
} from "src/components";
import TableCustomHead from "src/components/TableCustomHead";
import TableToolbar from "src/components/TableToolbar";
import { useAppContext } from "src/hooks";
import MoreMenu from "src/components/MoreMenu";
const TABLE_HEAD = [
    { id: "", label: "#", alignRight: false },
    { id: "title", label: "Title", alignRight: false },
    { id: "description", label: "Description", alignRight: false },
    { id: "features_count", label: "Features Count", alignRight: false },
    { id: "views_count", label: "Views Count", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "version", label: "Version", alignRight: false },
    { id: "createdAt", label: "Created At", alignRight: false },
    { id: "updatedAt", label: "Last Updated", alignRight: false },
    { id: "action", label: "Action" },
];
function ManageUpdates() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { setBadge } = useAppContext();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [updatesList, setUpdatesList] = useState([]);
    const [filterName, setFilterName] = useState("");
    const [selectedTab, setSelectedTab] = useState("open");
    const [counts, setCounts] = useState({ open: 0, all: 0, closed: 0 });
    const [menuOpen, setMenuOpen] = useState(null);
    const [currentTicket, setCurrentTicket] = useState();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [closeDialogOpen, setCloseDialogOpen] = useState(false);
    const [deleteTicketLoading, setDeleteTicketLoading] = useState(false);
    const [closeTicketLoading, setCloseTicketLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState();


    const menuOptions = [
        {
            label: "Edit Update",
            icon: "mdi:calendar-edit",
            onClick: (item) => {
                setSelectedItem(item);
                console.log(item);
                navigate("/add-update", { state: { ...item } });
            },
        },
        {
            label: "Delete Update",
            icon: "mdi:delete",
            onClick: (item) => {
                setSelectedItem(item);
                console.log(item);
                // console.log(item);
                setDeleteDialogOpen(true);
            },
        },
    ];

    const confirmDelete = async () =>{
        try {
            // setLoading(true);
            let formData = new FormData();
            formData.append("update_id",selectedItem._id);

            const result = await _delete_update_item(formData);
            if (result.code == 200) {
                enqueueSnackbar(result.message, { variant: "success" });
                setUpdatesList((prev)=>{
                    return prev.filter((item)=>{
                        return item._id !== selectedItem._id;
                    })
                });
            }
        } catch (error) {
            console.log(error, " catched while fetching support tickets list");
            enqueueSnackbar("Something went wrong", { variant: "error" });
        } finally {
            // setLoading(false);
            setDeleteDialogOpen(false);
        }
    };
        
    

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleCloseMenu = () => {
        setMenuOpen(null);
        setCurrentTicket();
    };
    const handleOpenMenu = (e) => {
        setMenuOpen(e.target);
    };

    const filteredTickets = () => {
        if (!filterName) {
            return [...updatesList];
        } else {
            return updatesList?.filter((x) =>
                x.title.toLowerCase().includes(filterName.toLowerCase())
            );
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getUpdatesList = async () => {
        try {
            setLoading(true);
            const result = await _get_updates_list();
            if (result.code == 200) {
                setUpdatesList(result.updates_list);
            }
        } catch (error) {
            console.log(error, " catched while fetching support tickets list");
            enqueueSnackbar("Something went wrong", { varinat: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUpdatesList();
    }, []);

    return (
        <Page title="Manage Updates">
            <Container maxWidth="xl">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="mb-4"
                >
                    <Typography variant="h4">Manage Updates</Typography>
                    <Button variant="contained" onClick={() => navigate("/add-update")}>
                        Add New Update
                    </Button>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-start"
                    mb={3}
                >

                </Stack>
                {loading && <CircularLoader />}
                {!loading && (
                    <Card
                        sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
                    >
                        <TableToolbar
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            moduleName="Manage Updates"
                            placeholder="Search Updates..."
                        />
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <TableCustomHead headLabel={TABLE_HEAD} />
                                    {!loading && (
                                        <TableBody>
                                            {filteredTickets()
                                                // .slice(
                                                //     page * rowsPerPage,
                                                //     page * rowsPerPage + rowsPerPage
                                                // )
                                                .map((row, index) => {
                                                    const {
                                                        title,
                                                        _id,
                                                        description,
                                                        viewed_by,
                                                        views_count,
                                                        version,
                                                        features_count,
                                                        status,
                                                        createdAt,
                                                        updatedAt,
                                                    } = row;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={_id}
                                                        // selected={unread_users.includes(
                                                        //     JSON.parse(localStorage.getItem("user")).user_id
                                                        //         ._id
                                                        // )}
                                                        >
                                                            <TableCell>
                                                                {rowsPerPage * page + (index + 1)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {title}
                                                            </TableCell>
                                                            <TableCell>
                                                                {description
                                                                    ? description.substring(0, 50)
                                                                    : "----"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {features_count}
                                                            </TableCell>
                                                            <TableCell>
                                                                {views_count}
                                                            </TableCell>

                                                            <TableCell>
                                                                {status === false && (
                                                                    <Label
                                                                        style={{ width: 70 }}
                                                                        variant="ghost"
                                                                        color="error"
                                                                    >
                                                                        Not Published
                                                                    </Label>
                                                                )}

                                                                {status === true && (
                                                                    <Label
                                                                        style={{ width: 70 }}
                                                                        variant="ghost"
                                                                        color="success"
                                                                    >
                                                                        Published
                                                                    </Label>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {version}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(createdAt).format("LLL")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(updatedAt).format("LLL")}
                                                            </TableCell>

                                                            <TableCell align="left">
                                                                <MoreMenu row={row} menuOptions={menuOptions} />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                    // });
                                                })}
                                        </TableBody>
                                    )}

                                    {filteredTickets().length === 0 && !loading && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                        <TablePagination
                            rowsPerPageOptions={[50, 100, 150]}
                            component="div"
                            count={filteredTickets()?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                )}
            </Container>
            <MuiDialog
                open={deleteDialogOpen}
                onToggle={()=>{
                    setDeleteDialogOpen(false);
                }}
                title="Delete Update"
                message={`Are you sure you want to delete this "${selectedItem? selectedItem.title : "Update" }" `}
                onAgree={confirmDelete}
            />
        </Page>
    );
}

export default ManageUpdates;
