import {
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import AddEditCurrencyDialogue from "./AddEditCurrencyDialogue";
import {
  Label,
  MuiDialog,
  Page,
  Scrollbar,
  SearchNotFound,
  TableCustomHead,
} from "src/components";
import MoreMenu from "src/components/MoreMenu";
import {
  add_currency,
  delete_currency,
  get_currency_list,
  update_currency,
} from "src/DAL/currency";

export default function CurrencyListing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [currencyList, setCurrencyList] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogueForAddEdit, setOpenDialogueForAddEdit] = useState(false);
  const [targetCurrency, setTargetCurrency] = useState({});
  const [formType, setFormType] = useState("add");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const getConfigurations = async () => {
    setIsLoading(true);
    const result = await get_currency_list();
    console.log(result, "result----");
    if (result.code == 200) {
      setCurrencyList([...result?.plan_currency_list]);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  const EditCurrency = (data) => {
    setTargetCurrency(data);
    setFormType("update");
    setOpenDialogueForAddEdit(true);
  };

  const AddCurrency = () => {
    setTargetCurrency({});
    setFormType("add");
    setOpenDialogueForAddEdit(true);
  };

  const handleAddUpdateCurrency = async (data) => {
    const postData = {
      ...data,
      status: data.status == "true" ? true : false,
      short_form: data.short_form.toUpperCase(),
    };

    let result = {};

    if (formType == "add") {
      result = await add_currency(postData);
    } else {
      result = await update_currency(targetCurrency._id, postData);
    }

    if (result.code === 200) {
      getConfigurations();
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenDialogueForAddEdit(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }

    return result;
  };

  const handleAgreeDeleteCurrency = async () => {
    const resp = await delete_currency(targetCurrency._id);
    if (resp.code == 200) {
      enqueueSnackbar(resp.message, { variant: "success" });
      getConfigurations();
      setOpenDeleteConfirmation(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const isUserNotFound = currencyList.length === 0;

  useEffect(() => {
    getConfigurations();
  }, []);

  const TABLE_HEAD = [
    { id: "number", type: "number", label: "", alignRight: false },

    { id: "full_form", label: "Currency", alignRight: false },
    { id: "symbol", label: "Symbol", alignRight: false },
    { id: "short_form", label: "Currency Code", alignRight: false },
    {
      id: "status",
      label: "Status",
      alignCenter: true,
      className: "text-center",
      type: "row_status",
    },
    {
      id: "action",
      label: "Action",
      alignRight: false,
      type: "action",
      className: "text-center",
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit Currency",
      icon: "akar-icons:edit",
      onClick: EditCurrency,
    },

    {
      label: "Delete Currency",
      icon: "ant-design:delete-twotone",
      onClick: (data) => {
        setOpenDeleteConfirmation(true);
        setTargetCurrency(data);
      },
    },
  ];

  // if (isLoading === true) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }
  return (
    <>
      <AddEditCurrencyDialogue
        open={openDialogueForAddEdit}
        handleClose={() => setOpenDialogueForAddEdit(false)}
        formType={formType}
        targetData={targetCurrency}
        handleAddUpdate={handleAddUpdateCurrency}
      />

      {openDeleteConfirmation && (
        <MuiDialog
          open={openDeleteConfirmation}
          onToggle={() => {
            setOpenDeleteConfirmation(false);
          }}
          title="Delete Currency"
          message={`Are you sure you want to delete this currency "${targetCurrency.full_form}"?`}
          onAgree={handleAgreeDeleteCurrency}
        />
      )}

      <Page title="Plans">
        <Container maxWidth="xl">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4.5}
          >
            <Typography variant="h4">Currency</Typography>
            <Button variant="contained" onClick={() => AddCurrency()}>
              Add Currency
            </Button>
          </Stack>

          <Card
            sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
          >
            {/* <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Search Users..."
          /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableCustomHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {!isLoading &&
                      currencyList
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const { _id, full_form, symbol, short_form, status } =
                            row;

                          return (
                            <TableRow hover key={_id}>
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {index + 1}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {full_form}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {symbol}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {short_form}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Label
                                  variant="ghost"
                                  color={(!status && "error") || "success"}
                                >
                                  {status ? "Active" : "In-Active"}
                                </Label>
                              </TableCell>
                              <TableCell>
                                <MoreMenu
                                  row={row}
                                  menuOptions={MENU_OPTIONS}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                  {isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <div className="d-flex justify-content-center align-items-center w-100">
                            <CircularProgress />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isUserNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
    </>
  );
}
